import React from "react";
import "@fontsource/lilita-one";
import { Link } from "react-router-dom";

const PersonalBusinessBody = () => {
  return (
    <div>
      {/* header */}
      <div className="px-4 py-1 flex justify-between items-center flex-wrap border-b border-black">
        <div className="flex gap-3 sm:gap-5  items-center">
          <Link to="/">
            <p
              className="font-[Lilita One] text-[40px] text-[#F8A529] font-[400] cursor-pointer"
              style={{ fontFamily: "Lilita One" }}
            >
              Silver oak
            </p>
          </Link>
        </div>
      </div>
      <section>
        <div className="container my-5 ">
          <div className="row bg-secondary p-5">
            <div className="container">
              <div className="row bg-white p-5">
                <div className="col-md-2" />
                <div className="col-md-7">
                  <div className="">
                    <h4 class="text-center">Personal &amp; business info</h4>
                    <p class="mt-3">
                      your photo ID and phone, then follow the steps to keep
                      selling on Fiverr.
                    </p>
                    <p>
                      To start, scan the QR code or get a link via text message.
                    </p>
                  </div>
                  <div className="row ">
                    <div className="col-md-4">
                      <p>Send me a link to:</p>
                      <p>*******883</p>
                      <Link to='/ProfessionalOne'>
                      <button type="button" className="btn btn-primary">
                        Send Link{" "}
                      </button>
                      </Link>
                    </div>
                    <div className="col-md-4 p-5" />
                    <div className="col-md-4"></div>
                  </div>
                  <div className="row mt-3">
                    <p>
                      Once you finish verifying your ID on your mobile phone
                      you'll need to head back here to finish up
                    </p>
                  </div>
                  <hr />
                  <div className="row mt-3">
                    <p>
                      <span>
                        <i className="fa-solid fa-lock" />
                      </span>
                      Your privacy is important to us. We will never share your
                      ID with other users. Learn more
                    </p>
                  </div>
                </div>
                <div className="col-md-2" />
              </div>
            </div>
          </div>
          <hr />
          <section>
            <div className="doorStep pb-5">
              <div className="container">
                <div className="row doorStepBack">
                  <div className="col-md-12">
                    <div className="doorStepof">
                      <h5>Door Step of Freelance services !</h5>
                    </div>
                    <div className="doorStepofbtn">
                      <a href="javascript:void(0)">Join Silver oak</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <hr />
        </div>
      </section>
    </div>
  );
};

export default PersonalBusinessBody;
