import React from 'react'
import ReviewBuyerBody from '../components/ReviewBuyerBody'

const ReviewBuyer = () => {
  return (
    <div>
      <ReviewBuyerBody/>
    </div>
  )
}

export default ReviewBuyer
