import React from 'react'

const ReviewSellerBody = () => {
  return (
    <div>
       {/* header */}
    <div className="px-4 py-1 flex justify-between items-center flex-wrap border-b border-black">
        <div className="flex gap-3 sm:gap-5  items-center">
          <p className="font-[Lilita One] text-[40px] text-[#F8A529] font-[400] cursor-pointer" style={{fontFamily: 'Lilita One'}}>Silver oak</p>
          
        </div>
        
       
    </div>
      <>
  <section>
    <div className="container">
      <div className="row mt-5">
        <div className="col-md-4">
          <h1>Ready to review your brief?</h1>
          <p className="text-xs">
            We'll match your brief to select sellers-who'll then send offers to
            your inbox.
          </p>
        </div>
        <div className="col-md-7">
          <div className="d-flex justify-content-end">
            <button type="button" className="btn btn-danger">
              Edit
            </button>
          </div>
          <div className="">
            <h5>Brief summary</h5>
            <h5>Title</h5>
            <p className="text-secondary">web developer</p>
          </div>
          <div className="row">
            <div className="col-sm">
              Industry
              <p className="mt-3 text-secondary">Technology</p>
            </div>
            <div className="col-sm">
              Service
              <p className="mt-3 text-secondary">Website Design</p>
            </div>
          </div>
          <div>
            <h5>Description</h5>
            <p>
              A web developer is a professional responsible for designing,
              building, and maintaining websites and web applications. Their
              role involves understanding client requirements, translating them
              into technical specifications, and using programming languages
              like HTML, CSS, and JavaScript to create functional and visually
              appealing web solutions.
            </p>
          </div>
          <div className="row">
            <div className="col-sm">
              App purpose
              <p className="text-secondary mt-3">Ecommerce</p>
            </div>
            <div className="col-sm">
              App type
              <p className="text-secondary mt-3">Hybrid</p>
            </div>
            <div className="col-sm">
              Developer expertise
              <p className="text-secondary mt-3">Performance</p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              Extras
              <p className="mt-3 text-secondary">Responsive design</p>
            </div>
          </div>
          <div>
            <h4>Budget &amp; timeline</h4>
          </div>
          <div className="row">
            <div className="col-sm">
              Budget
              <p className="text-secondary mt-3">₹10,000 (flexible)</p>
            </div>
            <div className="col-sm">
              Deliver by
              <p className="text-secondary mt-3">Apr 26, 2024</p>
            </div>
            <div className="d-flex justify-content-end">
              <button type="button" className="btn btn-danger">
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end mt-5">
        <button type="button" className="btn btn-primary">
          Revlew you Brief
        </button>
      </div>
    </div>
  </section>
  <hr />
  <section>
    <div className="doorStep pb-5">
      <div className="container">
        <div className="row doorStepBack">
          <div className="col-md-12">
            <div className="doorStepof">
              <h5>Door Step of Freelance services !</h5>
            </div>
            <div className="doorStepofbtn">
              <a href="javascript:void(0)">Join Silver oak</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <hr />
</>

    </div>
  )
}

export default ReviewSellerBody
