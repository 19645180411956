import React from 'react'
import ProRequireBody from '../components/ProRequireBody'

const ProRequire = () => {
  return (
    <div>
      <ProRequireBody/>
    </div>
  )
}

export default ProRequire
