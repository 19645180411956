import React from 'react'
import BudgetSellerBody from '../components/BudgetSellerBody'

const BudgetSeller = () => {
  return (
    <div>
      <BudgetSellerBody/>
    </div>
  )
}

export default BudgetSeller
