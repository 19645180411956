import React from "react";
import { Link } from "react-router-dom";
import "@fontsource/lilita-one";

const Professionalbody = () => {
  return (
    <div>
      {/* header */}
      <div className="px-4 py-1 flex justify-between items-center flex-wrap border-b border-black">
        <div className="flex gap-3 sm:gap-5  items-center">
          <Link to='/'>
          <p
            className="font-[Lilita One] text-[40px] text-[#F8A529] font-[400] cursor-pointer"
            style={{ fontFamily: "Lilita One" }}
          >
            Silver oak
          </p></Link>
        </div>
      </div>
      <>
        <section>
          <div className="container">
            <div className="row mt-5">
              <div className="col-md-8">
                <nav className="flex" aria-label="Breadcrumb">
                  <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                    <li className="inline-flex items-center">
                      <Link
                        to="/Personalinform"
                        className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
                      >
                        <svg
                          className="w-3 h-3 me-2.5"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                        </svg>
                        Personal Info
                      </Link>
                    </li>
                    <li>
                      <div className="flex items-center">
                        <svg
                          className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 6 10"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="m1 9 4-4-4-4"
                          />
                        </svg>
                        <Link
                          to="/Professional"
                          className="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white"
                        >
                          Professional info
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div className="flex items-center">
                        <svg
                          className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 6 10"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="m1 9 4-4-4-4"
                          />
                        </svg>
                        <Link
                          to="/Accountsecurity"
                          className="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white"
                        >
                          AccountSecurity
                        </Link>
                      </div>
                    </li>
                  </ol>
                </nav>

                <div className="row mt-5">
                  <h5>Professional Info</h5>
                  <p>
                    This is your time to shine. Let potential buyers know what
                    <br /> you do best and how you gained your skills,
                    certifications and
                    <br />
                    experiencev
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="progressPart">
                  <label htmlFor="file">Completion Rate 75%</label>&nbsp;&nbsp;
                  <progress id="file" value={25} max={100} />
                </div>
                <div className="mandatoryField">
                  <p>* mandatory fields</p>
                </div>
              </div>
            </div>
            <hr />
            {/*form code*/}
            <div className="container my-5">
              <div className="row">
                <div className="occupationPart">
                  <label htmlFor="">Your Occupation</label>
                  <input
                    type="text"
                    id="userId"
                    name="userId"
                    placeholder="Select Occupation"
                  />
                </div>
                <div className="occupationPartA mt-2">
                  <a href="">+ Add New</a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="skillsPart">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <div className="skillsPart1">
                    <h6>Skills</h6>
                    <span>
                      <i className="fa-sharp fa-solid fa-star" />
                    </span>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="skillsPart2">
                    <div className="skillsSection1">
                      <form>
                        <select id="course" name="course">
                          <option value="">HTML</option>
                          <option value="">CSS</option>
                          <option value="">JS</option>
                        </select>
                      </form>
                    </div>
                    <div className="skillsSection2">
                      <form>
                        <select id="course" name="course">
                          <option value="">Expert</option>
                          <option value="">Beginner</option>
                          <option value="">JS</option>
                        </select>
                      </form>
                    </div>
                    <div className="skillsSection3">
                      <a href="">Cancel</a>
                    </div>
                    <div className="skillsSection4">
                      <a href="">Update</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="profilePart">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <div className="profilePart1 mt-3">
                    <h6>Profile Picture</h6>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="profilePart2 mt-3">
                    <table>
                      <tbody>
                        <tr>
                          <th>Skills</th>
                          <th>Level</th>
                          <th>Add New</th>
                        </tr>
                        <tr>
                          <td>HTML</td>
                          <td>Intermediate</td>
                          <td />
                        </tr>
                        <tr>
                          <td>CSS Coding</td>
                          <td>Expert</td>
                          <td />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="profilePart">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <div className="profilePart1 mt-3">
                    <h6>Description</h6>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="profilePart2 mt-3">
                    <table>
                      <tbody>
                        <tr>
                          <th>Degree</th>
                          <th>Years</th>
                          <th>Add New</th>
                        </tr>
                        <tr>
                          <td>marketing-Penting</td>
                          <td>Graduated 2018</td>
                          <td />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="profilePart">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <div className="profilePart1 mt-3">
                    <h6>Certification</h6>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="profilePart2 mt-3">
                    <table>
                      <tbody>
                        <tr>
                          <th>Certificate or Award</th>
                          <th>Years</th>
                          <th>Add New</th>
                        </tr>
                        <tr>
                          <td>WisCube Tech digital marketing</td>
                          <td>2022</td>
                          <td />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="profilePart">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <div className="profilePart1 mt-3">
                    <h6>Personal Website</h6>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="profilePart2 mt-3">
                    <table>
                      <tbody>
                        <tr>
                          <td>WisCube Tech digital marketing</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="text-start mt-5">
                    <Link to='/AccountSecurity'>
                    <button type="button" className="btn btn-primary">
                      Continue
                    </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="doorStep pb-5">
            <div className="container">
              <div className="row doorStepBack">
                <div className="col-md-12">
                  <div className="doorStepof">
                    <h5>Door Step of Freelance services !</h5>
                  </div>
                  <div className="doorStepofbtn">
                    <a href="javascript:void(0)">Join Silver oak</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </div>
  );
};

export default Professionalbody;
