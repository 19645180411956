import React from "react";
import timingbudgetimg from "../images/computer_10049811 1.png";
import { Link } from "react-router-dom";
import "@fontsource/lilita-one";
import { GoBell } from "react-icons/go";
import { IoMailOutline } from "react-icons/io5";
import { IoMdHeartEmpty } from "react-icons/io";

const TimingBudgetBody = () => {
  return (
    <div>
      {/* header */}
      <div className="px-4 py-1 flex justify-between items-center flex-wrap border-b border-black">
        <div className="flex gap-3 sm:gap-5  items-center">
          <Link to='/'>
          <p
            className="font-[Lilita One] text-[40px] text-[#F8A529] font-[400] cursor-pointer"
            style={{ fontFamily: "Lilita One" }}
          >
            Silver oak
          </p></Link>
        </div>
        <div className="flex items-center gap-3 ">
          <div className="flex gap-2 items-center">
            <div className="text-[18px] font-[600] cursor-pointer h-[23px] w-[23px] bg-[#0C2563] text-white rounded-[50%] flex items-center justify-center ">
              1
            </div>
            <div className="text-[17px] font-[600] text-[#494949] ">
              share brief description
            </div>
          </div>
          <div className="flex gap-2 items-center">
            <div className="text-[18px] font-[600] cursor-pointer h-[23px] w-[23px] bg-[#0C2563] text-white rounded-[50%] flex items-center justify-center ">
              2
            </div>
            <div className="text-[17px] font-[600] text-[#494949] ">
              Add timeline and budget{" "}
            </div>
          </div>
        </div>
        <div className="flex gap-3 items-center">
          <div className="text-[18px] font-[600] cursor-pointer ">Exit</div>
        </div>
      </div>
      <>
        <div className="secondLayout">
          <div className="container">
            <div className="row mt-5">
              <div className="col-md-4">
                <div className="leftSides">
                  <h1>Now let’s talk budget and timing</h1>
                  <p>
                    You're not committing to anything final here. This just
                    helps us find you the most relevant
                  </p>
                  <img
                    src={timingbudgetimg}
                    width={200}
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-md-8">
                <div className="rightSides">
                  <div className="col-md-8">
                    <form>
                      <div className="form-group">
                        <label htmlFor="formGroupExampleInput">
                          I'm looking to spend...
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="formGroupExampleInput"
                          placeholder={10000}
                        />
                        <p className="text-secondary">
                          Average range for this service: 5,258.75-70,116.64
                          <span>
                            <i className="fa-solid fa-question" />
                          </span>
                        </p>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox1"
                          defaultValue="option1"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineCheckbox1"
                        >
                          My budget is flexible
                        </label>
                      </div>
                      <div id="calendar">
                        <label htmlFor="date">Select a date:</label>
                        <input type="date" id="date" name="date" />
                      </div>
                    </form>

                    {/* <input type="radio" class="btn-check" name="options" id="option1" autocomplete="off" checked>
                  <label class="btn btn-secondary" for="option1">Upload to app store</label>
                  <input type="radio" class="btn-check" name="options" id="option2" autocomplete="off">
                  <label class="btn btn-secondary" for="option2">App icon</label>
                  <input type="radio" class="btn-check" name="options" id="option3" autocomplete="off" disabled>
                  <label class="btn btn-secondary" for="option3">Splash screen</label>
                  <input type="radio" class="btn-check" name="options" id="option4" autocomplete="off">
                  <label class="btn btn-secondary" for="option4">Include source code</label> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="mt-5 d-flex justify-content-start">
              <Link to='/Begib'>
              <button type="button" className="btn btn-secondary">
                Back
              </button>
              </Link>
            </div>
            <div className=" d-flex justify-content-end">
              <Link to="/ReviewBuyer">
                <button type="button" className="btn btn-primary">
                  Review you Brief
                </button>
              </Link>
            </div>
          </div>
        </div>
        <section>
          <div className="doorStep pb-5">
            <div className="container">
              <div className="row doorStepBack">
                <div className="col-md-12 ">
                  <div className="doorStepof">
                    <h5>Door Step of Freelance services !</h5>
                  </div>
                  <div className="doorStepofbtn">
                    <a href="javascript:void(0)">Join Silver oak</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <hr />
      </>
    </div>
  );
};

export default TimingBudgetBody;
