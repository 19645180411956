import React, { useState } from "react";
import { Link } from "react-router-dom";
import SILVEROAKLOGO from "../images/SILVEROAK LOGO.png";
import "../pages/style/style.css";
import bannerbg from "../images/bannerBack.png";

const Header = ({setPopUp}) => {
  const [showNav, setShowNav] = useState(false);
 
  return (
    <div>
      <header className="z-50">
        <nav class="bg-[#35595833] border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800">
          <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
            <Link to="./" class="flex items-center">
              <img
                src={SILVEROAKLOGO}
                className="mr-3   "
                alt=""
                style={{ height: "100px", width: "150px" }}
              />
            </Link>
            <div class="flex items-center lg:order-2">
             
                <button class=" sm:block hidden text-white bg-orange-400 hover:bg-blue-800 
                focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 lg:px-5
                 py-2 lg:py-2.5 mr-2 dark: dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800
                  bg-[ #F8A529]" onClick={()=>setPopUp(true)}
             >
                Join
            </button>
              <button
                data-collapse-toggle="mobile-menu-2"
                type="button"
                class="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                aria-controls="mobile-menu-2"
                aria-expanded="false"
                onClick={() => setShowNav(!showNav)}
              >
                <span class="sr-only">Open main menu</span>
                <svg
                  class="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <svg
                  class="hidden w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <div
              class="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1"
              id="mobile-menu-2"
            >
              <ul class="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                <li>
                  <a
                    href="#"
                    class="block py-2 pr-4 pl-3 text-white rounded bg-blue-700 lg:bg-transparent lg:text-blue-700 lg:p-0 dark:text-white"
                    aria-current="page"
                  ></a>
                </li>
                <li>
                  <Link
                    to="./Explore"
                    class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 
                     lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-Dark dark:hover:bg-gray-700 dark:hover:text-Dark lg:dark:hover:bg-transparent dark:border-gray-700"
                  >
                    Explore
                  </Link>
                </li>
                <li>
                  <Link
                    to="/SellingOnBuyer"
                    class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-Dark dark:hover:bg-gray-700 dark:hover:text-Dark lg:dark:hover:bg-transparent dark:border-gray-700"
                  >
                    Talent Seller
                  </Link>
                </li>
                <li>
                  <Link
                    to="./Begib"
                    class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-dark dark:hover:bg-gray-700 dark:hover:text-dark lg:dark:hover:bg-transparent dark:border-gray-700"
                  >
                    Buyer
                  </Link>
                </li>
                <li>
                  <a
                    href="#"
                    class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-dark dark:hover:bg-gray-700 dark:hover:text-dark lg:dark:hover:bg-transparent dark:border-gray-700"
                  >
                    Sign in
                  </a>
                </li>
              </ul>
            </div>

            {/* responsive nav items */}
            <div
              class={`justify-between items-center  w-full lg:hidden lg:w-auto lg:order-1 duration-1000
               ${showNav ? "flex" : "hidden"}`}
              id="mobile-menu-2"
            >
              <ul class="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                <li>
                  <a
                    href="#"
                    class="block py-2 pr-4 pl-3 text-white rounded bg-blue-700 lg:bg-transparent lg:text-blue-700 lg:p-0 dark:text-white"
                    aria-current="page"
                  >
                    Explore
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    class="block py-2 pr-4 pl-3 text-gray-700   hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                  >
                    Talent Seller
                  </a>
                </li>
                <li>
                  <Link
                    to
                    href="/Begib"
                    class="block py-2 pr-4 pl-3 text-gray-700  hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                  >
                    Buyer
                  </Link>
                </li>
                <li>
                  <a
                    href="#"
                    class="block py-2 pr-4 pl-3 text-gray-700  hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                  >
                    Sign In
                  </a>
                </li>
                <li>
                  {" "}
                  <a
                    href="#"
                    class="text-white bg-orange-400 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark: dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 bg-[ #F8A529]"
                  >
                    Join
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;
