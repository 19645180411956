import React from 'react'
import Professionalbody from '../components/Professionalbody'

const Professional = () => {
  return (
    <div>
      <Professionalbody/>
    </div>
  )
}

export default Professional
