import React from "react";
import { Link } from "react-router-dom";
import "@fontsource/lilita-one";

const AccountSecurityBody = () => {
  return (
    <div>
      <>
        {/* header */}
        <div className="px-4 py-1 flex justify-between items-center flex-wrap border-b border-black">
          <div className="flex gap-3 sm:gap-5  items-center">
            <Link to="/">
              <p
                className="font-[Lilita One] text-[40px] text-[#F8A529] font-[400] cursor-pointer"
                style={{ fontFamily: "Lilita One" }}
              >
                Silver oak
              </p>
            </Link>
          </div>
        </div>
        <>
          <section>
            <div className="container">
              <div className="row mt-5">
                <div className="col-md-8">
                  <nav className="flex" aria-label="Breadcrumb">
                    <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                      <li className="inline-flex items-center">
                        <Link
                          to="/Personalinform"
                          className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
                        >
                          <svg
                            className="w-3 h-3 me-2.5"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                          </svg>
                          Personal Info
                        </Link>
                      </li>
                      <li>
                        <div className="flex items-center">
                          <svg
                            className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 6 10"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="m1 9 4-4-4-4"
                            />
                          </svg>
                          <Link
                            to="/Professional"
                            className="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white"
                          >
                            Professional info
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="flex items-center">
                          <svg
                            className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 6 10"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="m1 9 4-4-4-4"
                            />
                          </svg>
                          <Link
                            to="/Accountsecurity"
                            className="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white"
                          >
                            AccountSecurity
                          </Link>
                        </div>
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>

              {/*end*/}
            </div>

            <div class="container mt-5">
              <div class="row">
                <div class="col-md-6">
                  <h5>Account Security</h5>
                  <p>
                    Trust anst sahaty is a tug stual in our sommunity Please
                    wurify yma antall anst péna numse so that we can kaup your
                    account sнски
                  </p>
                  <p class="mt-3">
                    <span>
                      <i class="fa-solid fa-envelope"></i>
                    </span>
                    &nbsp;Email private
                  </p>

                  <p class="mt-3">
                    <span>
                      <i class="fa-solid fa-phone"></i>
                    </span>
                    &nbsp;Phone Number private
                  </p>
                  <p>We’ll never share you phone number</p>
                </div>

                <div class="col-md-6">
                  <div class=" text-center">
                    <button type="button" class="btn btn-primary ">
                      Verified
                    </button>
                  </div>
                  <div class="mt-5 text-center">
                    <button type="button" class="btn btn-primary  ">
                      Verified
                    </button>
                  </div>
                  <div class="mt-5 text-center">
                    <Link to='/PersonalBusiness'>
                    <button type="button" class="btn btn-success ">
                      Fines
                    </button></Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <hr />
          <>
            <section>
              <div className="doorStep pb-5">
                <div className="container">
                  <div className="row doorStepBack">
                    <div className="col-md-12">
                      <div className="doorStepof">
                        <h5>Door Step of Freelance services !</h5>
                      </div>
                      <div className="doorStepofbtn">
                        <a href="javascript:void(0)">Join Silver oak</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <hr />
          </>
        </>
      </>
    </div>
  );
};

export default AccountSecurityBody;
