import React from 'react'
import DoneBuyexrBody from '../components/DoneBuyerBody'

const DoneBuyer = () => {
  return (
    <div>
      <DoneBuyexrBody/>
    </div>
  )
}

export default DoneBuyer
