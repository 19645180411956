import React from 'react'
import ProDescriptionBody from '../components/ProDescriptionBody'

const ProDescription = () => {
  return (
    <div>
      <ProDescriptionBody/>
    </div>
  )
}

export default ProDescription
