import React from 'react'
import ProfessionaloneBody from '../components/ProfessionaloneBody'

const Professionalone = () => {
  return (
    <div>
      <ProfessionaloneBody/>
    </div>
  )
}

export default Professionalone
