import React from 'react'

import SellingOnBuyerTwoBody from '../components/SellingOnBuyerTwoBody'

const SellingOnBuyerTwo = () => {
  return (
    <div>
      <SellingOnBuyerTwoBody/>
    </div>
  )
}

export default SellingOnBuyerTwo
