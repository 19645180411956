import React from "react";
import { Link } from "react-router-dom";
import "@fontsource/lilita-one";

const PersonalInformBody = () => {
  return (
    <div>
      <>
        {/* header */}
        <div className="px-4 py-1 flex justify-between items-center flex-wrap border-b border-black">
          <div className="flex gap-3 sm:gap-5  items-center">
            <Link to='/'>
            <p
              className="font-[Lilita One] text-[40px] text-[#F8A529] font-[400] cursor-pointer"
              style={{ fontFamily: "Lilita One" }}
            >
              Silver oak
            </p></Link>
          </div>
        </div>
        <>
          <section>
            <div className="container">
              <div className="row mt-2">
                <div className="col-md-8">
                  <nav className="flex" aria-label="Breadcrumb">
                    <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                      <li className="inline-flex items-center">
                        <Link
                          to="/Personalinform"
                          className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
                        >
                          <svg
                            className="w-3 h-3 me-2.5"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                          </svg>
                          Personal Info
                        </Link>
                      </li>
                      <li>
                        <div className="flex items-center">
                          <svg
                            className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 6 10"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="m1 9 4-4-4-4"
                            />
                          </svg>
                          <Link
                            to="/Professional"
                            className="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white"
                          >
                            Professional info
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="flex items-center">
                          <svg
                            className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 6 10"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="m1 9 4-4-4-4"
                            />
                          </svg>
                          <Link
                            to="/Accountsecurity"
                            className="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white"
                          >
                            AccountSecurity
                          </Link>
                        </div>
                      </li>
                    </ol>
                  </nav>

                  <div className="row mt-5">
                    <h5>Personal Info</h5>
                    <p>
                      Tell us a bit about yourself. This information will appear
                      on your public proffie, so that potential buyers can got
                      to know you better
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="progress">
                    Completion Rate 25% &nbsp;
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "25%" }}
                      aria-valuenow={25}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                </div>
              </div>
              {/*form code*/}
              <div className="container my-5">
                <div className="row">
                  <div className="col-lg-9">
                    <form>
                      <div className="row g-3">
                        <div className="col-md-6">
                          <label htmlFor="your-name" className="form-label">
                            Full Name* private
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="your-name"
                            name="your-name"
                            required=""
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="your-surname" className="form-label">
                            Your Surname
                          </label>
                          <input
                            type="text"
                            placeholder="last name"
                            className="form-control"
                            id="your-surname"
                            name="your-surname"
                            required=""
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="your-email" className="form-label">
                            Display Name*
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="your-email"
                            name="your-email"
                            required=""
                          />
                        </div>
                        <div className="col-12">
                          <label htmlFor="your-message" className="form-label">
                            Description
                          </label>
                          <textarea
                            className="form-control"
                            id="your-message"
                            name="your-message"
                            rows={5}
                            required=""
                            defaultValue={
                              "              A web developer is a professional responsible for designing, building, and maintaining websites and web applications. Their role involves understanding client requirements, translating them into technical specifications, and using programming languages like HTML, CSS, and JavaScript to create functional and visually appealing web solutions.\n            "
                            }
                          />
                        </div>
                        <div className="form-group col-md-3">
                          <label htmlFor="inputState">Language</label>
                          <select id="inputState" className="form-control">
                            <option selected="">Choose...</option>
                            <option>...</option>
                          </select>
                        </div>
                        <div className="form-group col-md-3">
                          <label htmlFor="inputState">Language Level </label>
                          <select id="inputState" className="form-control">
                            <option selected="">Choose...</option>
                            <option>...</option>
                          </select>
                        </div>
                        <div className="form-group col-md-3">
                          <label htmlFor="inputState" />
                          <button type="button" className="btn btn-secondary">
                            Cancel
                          </button>
                        </div>
                        <div className="form-group col-md-3 mt-3">
                          <label htmlFor="inputState" />
                          <button type="button" className="btn btn-secondary">
                            Add
                          </button>
                        </div>
                        <table className="table table-bordered">
                          <thead></thead>
                          <tbody>
                            <tr>
                              <td>Language</td>
                              <td>Level</td>
                              <td>Add new</td>
                            </tr>
                            <tr>
                              <td>English</td>
                              <td>Basic</td>
                            </tr>
                            <tr>
                              <td colSpan={2}>Hindi</td>
                              <td>Conversational</td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="col-12">
                          <div className="row">
                            <div className="col-md-6">
                              <Link to='/Professional'>
                              <button
                                data-res="<?php echo $sum; ?>"
                                type="submit"
                                className="btn btn-primary w-25fw-bold"
                              >
                                Continue
                              </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/*end*/}
            </div>
          </section>
          <hr />
          <>
            <section>
              <div className="doorStep pb-5">
                <div className="container">
                  <div className="row doorStepBack">
                    <div className="col-md-12">
                      <div className="doorStepof">
                        <h5>Door Step of Freelance services !</h5>
                      </div>
                      <div className="doorStepofbtn">
                        <a href="javascript:void(0)">Join Silver oak</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <hr />
          </>
        </>
      </>
    </div>
  );
};

export default PersonalInformBody;
