import React from 'react'
import { Link } from 'react-router-dom'
import '@fontsource/lilita-one';

const ReviewBuyerBody = () => {
  return (
    <div>
      {/* header */}
    <div className="px-4 py-1 flex justify-between items-center flex-wrap border-b border-black">
        <div className="flex gap-3 sm:gap-5  items-center">
          <Link to='/'>
          <p className="font-[Lilita One] text-[40px] text-[#F8A529] font-[400] cursor-pointer" style={{fontFamily: 'Lilita One'}}>Silver oak</p>
          </Link>
        </div>
        
        <div className="flex gap-3 items-center">
          <div className="text-[18px] font-[600] cursor-pointer ">Exit</div>
        </div>
    </div>
      <>
  <section>
    <div className="container">
      <div className="row mt-5">
        <div className="col-md-4">
          <h1>Ready to review your brief?</h1>
          <p className="text-xs">
            We'll match your brief to select sellers-who'll then send offers to
            your inbox.
          </p>
        </div>
        <div className="col-md-7">
          <div className="d-flex justify-content-end">
            <button type="button" className="btn btn-dark">
              Edit
            </button>
          </div>
          <div className="">
            <h5>Brief summary</h5>
            <h5><b>Title</b></h5>
            <p className="text-secondary">
              Develop an Android app for a startup
            </p>
          </div>
          <div className="row">
            <div className="col-sm">
              Industry
              <p className="mt-3 text-secondary">Art &amp; Design</p>
            </div>
            <div className="col-sm">
              Service
              <p className="mt-3 text-secondary">Cross-Platform Development</p>
            </div>
          </div>
          <div>
            <h5>Description</h5>
            <p>
              ime to get our hands dirty with coding! We'll use Java or Kotlin,
              along with Android Studio, to develop the app. This phase involves
              implementing the features outlined in the wireframes and
              integrating any necessary APIs or third-party services.
            </p>
          </div>
          <div className="row">
            <div className="col-sm">
              App purpose
              <p className="text-secondary mt-3">Ecommerce</p>
            </div>
            <div className="col-sm">
              App type
              <p className="text-secondary mt-3">Hybrid</p>
            </div>
            <div className="col-sm">
              Developer expertise
              <p className="text-secondary mt-3">Performance</p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              Extras
              <p className="mt-3 text-secondary">Upload to app store</p>
            </div>
          </div>
          <div>
            <h4>Budget &amp; timeline</h4>
          </div>
          <div className="row">
            <div className="col-sm">
              Budget
              <p className="text-secondary mt-3">₹10,000 (flexible)</p>
            </div>
            <div className="col-sm">
              Deliver by
              <p className="text-secondary mt-3">Apr 26, 2024</p>
            </div>
            <div className="d-flex justify-content-end">
              <button type="button" className="btn btn-dark">
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end mt-5">
        <Link to="">
        <button type="button" className="btn btn-primary">
          Submit &amp; get matched
        </button>
        </Link>
      </div>

     
    </div>
  </section>
  <hr />
</>

    </div>
  )
}

export default ReviewBuyerBody
