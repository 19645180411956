import React, { useState } from "react";
import dummyProfile1 from "../images/dummyProfile1.png";
import microsoft from "../images/microsoft.png";
import airbnb from "../images/airbnb.png";
import google from "../images/google.png";
import appDevelopment from "../images/appDevelopment.jpg";
import rightImg from "../images/rightImg.jpg";
import softwareDevelopment from "../images/softwareDevelopment.avif";
import webDevelopment from "../images/webDevelopment.avif";
import eCommerce from "../images/eCommerce.avif";
import gameDevelopment from "../images/gameDevelopment.jpg";
import image125 from "../images/image125.png";
import image127 from "../images/image127.png";
import image128 from "../images/image128.png";
import image15 from "../images/image15.png";
import image28 from "../images/image28.png";
import madeonImg1 from "../images/madeonImg1.png";
import madeonImg2 from "../images/madeonImg2.png";
import madeonImg3 from "../images/madeonImg3.png";
import madeonImg4 from "../images/madeonImg4.png";
import midbackground from "../images/midBackground.png";
import homebanner from "../images/homebanner.png";
import Devlopment from "../images/Devlopment.png";
import appdevlop from "../images/appdevlop.png";
import ebusiness from "../images/Ebusiness.png";
import aiservice from "../images/AIservice.png";
import enterprise from "../images/enterprise.png";
import softdev from "../images/softwareDevelopment.png";
import ecom from "../images/ecom.png";
import gameDev from "../images/gamedov.png";
import webdev from "../images/webdev.png";
import fortalent from "../images/fortalent.png";
import silveroak1 from "../images/silveroak1.png";
import silveroak2 from "../images/silveroak2.png";
import silveroak3 from "../images/silveroak3.png";
import silveroak4 from "../images/silvaeroak4.png";
import silveroak5 from "../images/silveroak5.png";
import silveroak6 from "../images/silveroak6.png";
import rewiewimg from "../images/reviewimg.png";
import Header from "./Header";
import popupimg from "../images/popupimg.png";
import { FcGoogle } from "react-icons/fc";
import { CiMail } from "react-icons/ci";
import { TiVendorApple } from "react-icons/ti";
import { TiSocialFacebook } from "react-icons/ti";

const IndexBody = () => {
  const [popUp , setPopUp]= useState(true)

  
  return (
    <div>
      <>
        <section>
          <section class="showcase">
            <img class=" max-w-full" src={homebanner} alt="image description" />

            {/* <img src={homebanner} alt="Picture" className="img-fluid" />*/}
            <div class="overlay text-start ">
            <Header className="bg-transparent" setPopUp={setPopUp} />
              <h2 className="pl-10">
                {" "}
                Find the right<br></br>
                <span className="text-warning">freelance</span>
                <br></br> service, right away
              </h2>

              <div
                class="relative flex pl-10"
                data-twe-input-wrapper-init
                data-twe-input-group-ref
              >
                <input
                  type="search"
                  class="peer block min-h-[auto] w-25 rounded border-2 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[twe-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-white dark:placeholder:text-neutral-300 dark:autofill:shadow-autofill dark:peer-focus:text-primary [&:not([data-twe-input-placeholder-active])]:placeholder:opacity-0"
                  placeholder="Search"
                  aria-label="Search"
                  id="search-input"
                  aria-describedby="search-button"
                />
                <label
                  for="search-input"
                  class="pointer-events-none absolute left-16
                  top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-white transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[twe-input-state-active]:-translate-y-[0.9rem] peer-data-[twe-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-400 dark:peer-focus:text-primary "
                  style={{ zIndex: "10000" }}
                >
                  Search
                </label>
                <button
                  class="relative z-[2] -ms-0.5 flex items-center rounded-e bg-[#5898C6] px-3  text-xs font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
                  type="button"
                  id="search-button"
                  data-twe-ripple-init
                  data-twe-ripple-color="dark"
                >
                  <span class="[&>svg]:h-5 [&>svg]:w-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                      />
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </section>
        </section>

        <section>
          <div className="forClient">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="forClientText mt-5">
                    <h1>All service</h1>
                  </div>
                  <div className="forClientBox mt-5 ">
                    <div className="AllserviceBox1 mt-3 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 ... ">
                      <div className="my-5 flex justify-center">
                        <img src={Devlopment} />
                      </div>
                      <p>Development & IT</p>
                    </div>
                    <div className="AllserviceBox1 mt-3 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 ...">
                      <div className="my-5 flex justify-center">
                        <img src={appdevlop} />
                      </div>
                      <p>APP Development</p>
                    </div>
                    <div className="AllserviceBox1 mt-3 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 ... ">
                      <div className="my-5 flex justify-center">
                        <img src={ebusiness} />
                      </div>
                      <p>E-Commerce</p>
                    </div>

                    <div className="AllserviceBox1 mt-3 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 ...">
                      <div className="my-5 flex justify-center">
                        <img src={aiservice} />
                      </div>
                      <p>AI Services</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="companyLogo">
            <div className="container my-5">
              <div className="row bg-[#3866DE]">
                <div className="col-md-12">
                  <div className="trustedby text-white text-center pt-5">
                    <h2>Trusted by</h2>
                  </div>
                  <div className="allcompanylogo">
                    <div className="allcompanylogo1">
                      <img src={microsoft} className="img-fluid" />
                    </div>
                    <div className="allcompanylogo1">
                      <img src={airbnb} className="img-fluid" />
                    </div>
                    <div className="allcompanylogo1">
                      <img src={microsoft} className="img-fluid" />
                    </div>
                    <div className="allcompanylogo1">
                      <img src={airbnb} className="img-fluid" />
                    </div>
                    <div className="allcompanylogo1">
                      <img src={google} className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="enterpriseSuite">
            <div className="container">
              <div className="row g-0">
                <div className="col-md-6">
                  <div className="leftSection">
                    <h5 className="text-danger">
                      <b>Enterprise Suite</b>
                    </h5>
                    <h3 className=" text-dark">This is how</h3>
                    <h3 className="text-blue-500">good company</h3>
                    <h3>find good company</h3>
                    <p>
                      Access the top 1% of talent on Silver oak and a full suite
                      of <br />
                      hybrid workfoce
                    </p>
                    <ul className="">
                      <li>Access export talent to fill your skill gape.</li>
                      <li>
                        Control your workflow: hire ,classify and pay you
                        talent.
                      </li>
                      <li>Partner with Silver oak for end-to end suport.</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="rightSection">
                    <img src={enterprise} className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="popularServices">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="popularServicesP mt-4 mb-5">
                    <h3>Popular Services</h3>
                  </div>
                  <div className="allpopularServices">
                    <div className="popularServices1 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 ...">
                      <h6>
                        App
                        <br /> Development
                      </h6>
                      <img src={appDevelopment} className="img-fluid" />
                    </div>
                    <div className="popularServices2 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 ...">
                      <h6>Software Development</h6>
                      <img src={softdev} className="img-fluid" />
                    </div>
                    <div className="popularServices3 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 ...">
                      <h6>
                        Web
                        <br /> Development
                      </h6>
                      <img src={webdev} className="img-fluid" />
                    </div>
                    <div className="popularServices4 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 ...">
                      <h6>E-Commerce</h6>
                      <img src={ecom} className="img-fluid" />
                    </div>
                    <div className="popularServices5 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 ...">
                      <h6>
                        Games
                        <br /> Development
                      </h6>
                      <img src={gameDev} className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div
            className="talentFind"
            style={{ backgroundImage: `url(${midbackground})` }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="midBackground">
                    <h2>Find Talent your way</h2>
                    <h6>
                      Work with the largest network of independent professionals
                      and
                      <br /> get things done -from quick turnarounds to big
                      transformations.
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="forClient ">
            <div className="container">
              <div className="row ">
                <div className="col-md-12">
                  <div className="forClientText mt-5">
                    <h1>For clients</h1>
                  </div>
                  <div className="forClientBox mt-5">
                    <div className="forClientBox1 border shadow-lg">
                      <div className=" flex justify-center ">
                        <img
                          src={image125}
                          className=" bg-dark shadow-[#00000] "
                        />
                      </div>
                      <h6>Post a job and hire a pro</h6>
                      <p className="mt-10 text-blue-500">Talent Marketplace</p>
                    </div>
                    <div className="forClientBox1 border shadow-lg">
                      <div className=" flex justify-center ">
                        <img src={image127} className="shadow-5xl bg-dark" />
                      </div>
                      <h6>Browse and buy projects</h6>
                      <p className="text-blue-500">Project Catalog</p>
                    </div>
                    <div className="forClientBox1 border shadow-lg">
                      <div className=" flex justify-center">
                        <img src={image128} className=" bg-dark" />
                      </div>
                      <h6>Get advice for an industry expert</h6>
                      <p className="text-blue-500">Consultations</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="forTalent">
            <div className="container">
              <div className="row g-0">
                <div className="col-md-6">
                  <div className="talentLestPart">
                    <img src={fortalent} className="img-fluid" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="ml-10">
                    <h5>For talent</h5>
                    <h1>
                      Find Great <br /> Developers
                    </h1>
                    <p>
                      Meet clients you’re exited to work with and take
                      <br /> your career or business to new heights.
                    </p>
                    <div className="horizontalLine" />
                    <div className="threeColumn mt-4">
                      <div className="threeColumn1">
                        <p>
                          Find opportunities for every stage of your Freelance
                          career
                        </p>
                      </div>
                      <div className="threeColumn1">
                        Control when,where, and how you work
                      </div>
                      <div className="threeColumn1">
                        Control when,where, and how you work
                      </div>
                    </div>
                    <div className="forButton">
                      <a href="javascript:void(0)">Join Silver oak</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="timandDan">
            <div className="container">
              <div className="row g-0">
                <div className="col-md-6">
                  <div className="timandDanLeft">
                    <h5>Co-Founders |</h5>
                    <p className="text-secondary">
                      "When you want to create a business bigger than yourself,{" "}
                      <br />
                      you need a lot of help. That’s what Silver oak does”
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="timandDanRight">
                    <h6>What they’re saying t Silver oak</h6>
                    <img src={image28} className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container mt-5">
            <div>
              <h3>
                <b>Made on Silver oak</b>
              </h3>
            </div>
          </div>
          <div class="container mx-auto px-4">
            <section class="pt-8 px-4">
              <div class="flex flex-wrap -mx-4">
                <div class="md:w-1/3 px-4 mb-8">
                  <img class="rounded shadow-md" src={silveroak1} alt="" />
                </div>
                <div class="md:w-1/3 px-4 mb-8">
                  <img class="rounded shadow-md" src={silveroak2} alt="" />
                </div>
                <div class="md:w-1/3 px-4 mb-8">
                  <img class="rounded shadow-md" src={silveroak3} alt="" />
                </div>
                <div class="md:w-1/3 px-4 mb-8">
                  <img class="rounded shadow-md" src={silveroak4} alt="" />
                </div>
                <div class="md:w-1/3 px-4 mb-8">
                  <img class="rounded shadow-md" src={silveroak5} alt="" />
                </div>
                <div class="md:w-1/3 px-4 mb-8">
                  <img class="rounded shadow-md" src={silveroak6} alt="" />
                </div>
              </div>
            </section>
          </div>
        </section>

        <section>
          <div className="doorStep pb-5">
            <div className="container">
              <div className="row doorStepBack">
                <div className="col-md-12">
                  <div className="doorStepof">
                    <h5>Door Step of Freelance services !</h5>
                  </div>
                  <div className="doorStepofbtn">
                    <a href="javascript:void(0)">Join Silver oak</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <section className="text-gray-600 body-font">
            <div className="container px-5 py-24 mx-auto">
              <div className="flex flex-wrap -m-4 bg-[#3535DE]">
                <div className="p-4 md:w-1/3 w-full  text-white">
                  <div className="h-full  p-8 rounded bg-[#3535DE] ">
                    <div class="flex items-center  mb-5">
                      <svg
                        class="w-6 h-6 ms-2 text-[#F8A529]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 22 20"
                      >
                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                      </svg>
                      <svg
                        class="w-6 h-6 ms-2 text-[#F8A529]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 22 20"
                      >
                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                      </svg>
                      <svg
                        class="w-6 h-6 ms-2 text-[#F8A529]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 22 20"
                      >
                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                      </svg>
                      <svg
                        class="w-6 h-6 ms-2 text-[#F8A529]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 22 20"
                      >
                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                      </svg>
                      <svg
                        class="w-6 h-6 ms-2 text-gray-300 dark:text-gray-500"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 22 20"
                      >
                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                      </svg>
                    </div>

                    <p className="leading-relaxed mb-6">
                      The work was completed and it was amazing. I definitely
                      recommend to have any website done . He was very
                      understanding and helpful and really took my vision and
                      what I had and built apon it and brought it to life and
                      it’s phenomenal what he can do. 10/10 rating for sure.
                    </p>
                    <a className="inline-flex items-center">
                      <img
                        alt="testimonial"
                        src={rewiewimg}
                        className="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center"
                      />
                      <span className="flex-grow flex flex-col pl-4">
                        <span className="title-font font-medium text-white">
                          trezmainia21
                        </span>
                        <span className="text-white text-sm">UI DEVELOPER</span>
                      </span>
                    </a>
                  </div>
                </div>
                <div className="p-4 md:w-1/3 w-full">
                  <div className="h-full  p-8 rounded bg-[#3535DE] text-white">
                    <div class="flex items-center  mb-5">
                      <svg
                        class="w-6 h-6 ms-2 text-[#F8A529]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 22 20"
                      >
                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                      </svg>
                      <svg
                        class="w-6 h-6 ms-2 text-[#F8A529]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 22 20"
                      >
                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                      </svg>
                      <svg
                        class="w-6 h-6 ms-2 text-[#F8A529]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 22 20"
                      >
                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                      </svg>
                      <svg
                        class="w-6 h-6 ms-2 text-[#F8A529]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 22 20"
                      >
                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                      </svg>
                      <svg
                        class="w-6 h-6 ms-2 text-gray-300 dark:text-gray-500"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 22 20"
                      >
                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                      </svg>
                    </div>

                    <p className="leading-relaxed mb-6">
                      I couldn't be more thrilled with the outstanding service
                      provided by Usama and his exceptional team. From start to
                      finish, they went above and beyond, demonstrating
                      unparalleled dedication and professionalism. Usama and his
                      team exhibited a...
                    </p>
                    <a className="inline-flex items-center">
                      <img
                        alt="testimonial"
                        src={rewiewimg}
                        className="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center"
                      />
                      <span className="flex-grow flex flex-col pl-4">
                        <span className="title-font font-medium text-white">
                          mattzc
                        </span>
                        <span className="text-white  text-sm">DESIGNER</span>
                      </span>
                    </a>
                  </div>
                </div>
                <div className="p-4 md:w-1/3 w-full">
                  <div className="h-full  p-8 rounded bg-[#3535DE] text-white">
                    <div class="flex items-center  mb-5">
                      <svg
                        class="w-6 h-6 ms-2 text-[#F8A529]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 22 20"
                      >
                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                      </svg>
                      <svg
                        class="w-6 h-6 ms-2 text-[#F8A529]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 22 20"
                      >
                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                      </svg>
                      <svg
                        class="w-6 h-6 ms-2 text-[#F8A529]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 22 20"
                      >
                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                      </svg>
                      <svg
                        class="w-6 h-6 ms-2 text-[#F8A529]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 22 20"
                      >
                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                      </svg>
                      <svg
                        class="w-6 h-6 ms-2 text-gray-300 dark:text-gray-500"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 22 20"
                      >
                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                      </svg>
                    </div>

                    <p className="leading-relaxed mb-6">
                      I couldn't be more thrilled with the outstanding service
                      provided by Usama and his exceptional team. From start to
                      finish, they went above and beyond, demonstrating
                      unparalleled dedication and professionalism. Usama and his
                      team exhibited a...
                    </p>
                    <a className="inline-flex items-center">
                      <img
                        alt="testimonial"
                        src={rewiewimg}
                        className="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center"
                      />
                      <span className="flex-grow flex flex-col pl-4">
                        <span className="title-font font-medium text-white">
                          sanchezzj94
                        </span>
                        <span className="text-white text-sm">DESIGNER</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </>

      {/* Pop up */}
      <div className={`${popUp ? 'fixed' : 'hidden'} w-[100vw] h-[100vh] top-0 left-0  z-[1000000] bg-black bg-opacity-75 cursor-pointer overflow-hidden `} onClick={()=>setPopUp(false)}>
      </div>
      
      <div className={`${popUp ? 'flex fixed' : 'hidden'}  items-center overflow-hidden overflow-y-auto sm:w-[570px] lg:w-[910px] rounded-[15px]  left-[50%] translate-x-[-50%] top-3 cursor-default z-[10000000] `}>
        <div className="h-[600px] max-w-[455px]  hidden  sm:block w-[50%] bg-[#FFA199] overflow-hidden border-r ">
          <div className="pl-8 pr-4 pt-4 text-white">
            <p className="text-[32px] font-[500] "> Success starts here</p>
            <p className="text-[20px] font-[600] flex items-center gap-3">
              <span className="h-[9px] w-[9px] bg-white rounded-[50%] inline-block"></span>
              Over 600 categories
            </p>
            <p className="text-[20px] font-[600] flex items-center gap-3">
              <span className="h-[9px] w-[9px] bg-white rounded-[50%] inline-block"></span>
              Pay per project, note per hour
            </p>
            <p className="text-[20px] font-[600] flex  gap-3">
              <span className=" mt-[10px] h-[9px] w-[9px] bg-white rounded-[50%] inline-block"></span>
              Access to talent and businesses across the globe
            </p>
          </div>

          <div>
            <img src={popupimg} alt="" />
          </div>
        </div>

        <div className="h-[600px] w-[80vw]   lg:max-w-[455px] sm:w-[50%] relative bg-white  overflow-hidden    p-4 pt-5">
          <p className="text-[25px] font-[600] text-center">
            Create a new account
          </p>
          <p className="text-[17px] font-[400] text-center">
            Already have an account? sign in
          </p>

          <div className="h-[51px] border-[2px] rounded-[17px] border-[#E8E8E8] flex items-center justify-center text-[17px] font-[400] cursor-pointer relative mt-5 ">
            <FcGoogle className=" absolute top-2 left-5 h-[30px] w-[30px]  " />
            Continue with google
          </div>
          <div className="h-[51px] border-[2px] rounded-[17px] border-[#E8E8E8] flex items-center justify-center text-[17px] font-[400] cursor-pointer relative mt-4 ">
            <CiMail className=" absolute top-2 left-5 h-[30px] w-[30px] " />
            Continue with email
          </div>

          <p className="text-[21px] font-[500] text-center my-4">or</p>

          <div className="flex justify-around  mt-4">
            <div className="text-[17px] font-[400] w-[40%] rounded-[17px] border border-[#E8E8E8] py-2 text-center relative cursor-pointer ">
              <TiVendorApple className=" absolute top-1 left-3 h-[30px] w-[30px] " />
              Apple
            </div>
            <div className="text-[17px] font-[400] w-[40%] rounded-[17px] border border-[#E8E8E8] py-2 text-center relative cursor-pointer ">
              <TiSocialFacebook className=" absolute top-1 left-3 h-[30px] w-[30px] text-[#6563ff] " />
              Facebook
            </div>
          </div>

            <p className="absolute w-full text-center  text-[12px] font-[400] text-[#253C76] bottom-4 left-[50%] translate-x-[-50%]">By Joining ,yu agree to the Silver oak <span className="border-b border-[#253C76] ">Terms of Service</span> and to</p>
        </div>
      
      </div>
    </div>
  );
};

export default IndexBody;
