import React from 'react'
import donersellerimg from '../images/donesellerimg.png';
import donesellerimg2 from '../images/donesellerimg2.png';
import '@fontsource/lilita-one';

const DoneSellerBody = () => {
  return (
    <div>
      {/* header */}
    <div className="px-4 py-1 flex justify-between items-center flex-wrap border-b border-black">
        <div className="flex gap-3 sm:gap-5  items-center">
          <p className="font-[Lilita One] text-[40px] text-[#F8A529] font-[400] cursor-pointer" style={{fontFamily: 'Lilita One'}}>Silver oak</p>
          
        </div>
        
       
    </div>
      <>
  <section>
    <div className="container">
      <div className="row mt-5">
        <div className="col-md-4">
          <img src={donersellerimg} />
          <div className="row">
            <h2>Your part is done, we’ll take it from here</h2>
          </div>
        </div>
        <div className="col-md-7">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="inlineCheckbox1"
              defaultValue="option1"
            />
            <label className="form-check-label" htmlFor="inlineCheckbox1">
              Send off your brief
            </label>
          </div>
          <div className="row mt-3">
            Keep an eye on your inbox, offers should arrive in no time
          </div>
          <div className="row border mt-5">
            <div className="col-sm p-5">
              Never miss an offer with the Silver oak
            </div>
            <div className="col-sm">
              <img src={donesellerimg2 } />
            </div>
          </div>
          <div className="form-check form-check-inline mt-5 text-secondary">
            <input
              className="form-check-input"
              type="checkbox"
              id="inlineCheckbox1"
              defaultValue="option1"
            />
            <label className="form-check-label" htmlFor="inlineCheckbox1">
              Order away
            </label>
          </div>
          <div className="d-flex justify-content-end mt-5">
            <button type="button" className="btn btn-primary">
              Got it &nbsp;{" "}
              <span>
                <i className="fa-solid fa-arrow-right" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <hr />
  <section>
    <div className="doorStep pb-5">
      <div className="container">
        <div className="row doorStepBack">
          <div className="col-md-12">
            <div className="doorStepof">
              <h5>Door Step of Freelance services !</h5>
            </div>
            <div className="doorStepofbtn">
              <a href="javascript:void(0)">Join Silver oak</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <hr />
</>

    </div>
  )
}

export default DoneSellerBody
