import React from "react";
import sobimg from "../images/sobimg.png";
import img120 from "../images/image 120.png";
import img122 from "../images/image 122.png";
import img123 from "../images/image 123.png";
import img124 from "../images/image 124.png";

import { Link } from "react-router-dom";

const SellingOnBuyerTwoBody = () => {
  return (
    <div>
      {/* header */}
      <div className="px-4 py-1 flex justify-between items-center flex-wrap border-b border-black">
        <div className="flex gap-3 sm:gap-5  items-center">
        <Link to='/'>
          <p
            className="font-[Lilita One] text-[40px] text-[#F8A529] font-[400] cursor-pointer m-0"
            style={{ fontFamily: "Lilita One" }}
          >
            Silver oak
          </p>
          </Link>
        </div>
      </div>

      <div className="container mt-5">
        <div className="row">
          <div className="col-md-3">
            <img src={sobimg} />
          </div>
          <div className="col-md-9 ">
            <div>
              <h5>
                <b>
                  Now, let's talk about the things you want to steer clear of .
                </b>
              </h5>
              <p className="text-secondary">
                Your success on Silver oak is important to us. Avoid the
                following to keep in line with our community standards:
              </p>
            </div>
            <div className="row mt-3">
              <div className="col-md-3 mt">
                <div>
                  <div className="px-5">
                    {" "}
                    <img src={img120} />
                  </div>
                  <p className="mt-3">
                    Providing any misleading or inaccurate information about
                    your identity.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="px-5">
                  {" "}
                  <img src={img122} />
                </div>
                <p className="mt-3">
                  Opening duplicate accounts. Remember, you can always create
                  more Gigs.
                </p>
              </div>
              <div className="col-md-3">
                <div className="px-5">
                  {" "}
                  <img src={img123} />
                </div>

                <p className="mt-3">
                  Soliciting other community members for work on Fiverr
                </p>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-3">
                <div>
                  <div className="px-5  ">
                    {" "}
                    <img src={img124} />
                  </div>
                  <p className="mt-3">
                    Requesting to take communication and payment outside of
                    Fiverr.
                  </p>
                </div>
              </div>
            </div>

            <div className="row justify content center mt-5">
              <div>
                <Link to='/PersonalInform'>
                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  Continue
                </button></Link>
                <Link to="/SellingOnBuyerone">
                  <button class="bg-[#555555] hover:bg-black-700 text-white font-bold py-2 px-4 rounded ml-5">
                    Back
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellingOnBuyerTwoBody;
