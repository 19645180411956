import React from 'react'
import SellingOnBuyerBody from '../components/SellingOnBuyerBody'

const SellingOnBuyer = () => {
  return (
    <div>
      <SellingOnBuyerBody/>
    </div>
  )
}

export default SellingOnBuyer
