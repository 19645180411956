import React from 'react'
import { Link } from 'react-router-dom'
import '@fontsource/lilita-one';
const VideoPageSellerBody = () => {
  return (
    <div>
        {/* header */}
    <div className="px-4 py-1 flex justify-between items-center flex-wrap border-b border-black">
        <div className="flex gap-3 sm:gap-5  items-center">
          <p className="font-[Lilita One] text-[40px] text-[#F8A529] font-[400] cursor-pointer" style={{fontFamily: 'Lilita One'}}>Silver oak</p>
          
        </div>
        
       
    </div>
      <>
  <section>
    <div className="mt-5">
      <h3 className="text-center">Ready to start selling on Fiverr?</h3>
      <h3 className="text-center">Here's the breakdown:</h3>
    </div>
  </section>
  <section>
    <div className="container">
      <div className="row mt-5">
        <div className="col-md-6">
       <div>
        <video className="h-full w-full rounded-lg" controls>
      <source src="https://docs.material-tailwind.com/demo.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    </div>
        </div>
        <div className="col-md-6">
          <div className="row">
            <h5>Learn what makes a successful profile</h5>
            <p>
              Discover the do's and don'ts to ensure you're always on the right
              track.
            </p>
          </div>
          <div className="row">
            <h5>Create your seller profile</h5>
            <p>
              Add your profile picture, description, and professional
              information
            </p>
          </div>
          <div className="row">
            <h5>Publish your Gig</h5>
            <p>
              Create a Gig of the service you're offering and start selling
              instantly.
            </p>
          </div>
          <div className=" d-flex justify-content-start">
          <Link to="/DoneBuyer">
            <button type="button" className="btn btn-primary">
              Continue &nbsp;
              <i className="fa-solid fa-arrow-right" />
            </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </section>
  <hr />
  <section>
    <div className="doorStep pb-5">
      <div className="container">
        <div className="row doorStepBack">
          <div className="col-md-12">
            <div className="doorStepof">
              <h5>Door Step of Freelance services !</h5>
            </div>
            <div className="doorStepofbtn">
              <a href="javascript:void(0)">Join Silver oak</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <hr />
</>

    </div>
  )
}

export default VideoPageSellerBody
