import React from "react";
import Breadcrums from "./Breadcrums";
import { Link } from "react-router-dom";
import '@fontsource/lilita-one';
const ProfessionalTwoBody = () => {
  return (
    <div>
      {/* header */}
    <div className="px-4 py-1 flex justify-between items-center flex-wrap border-b border-black">
        <div className="flex gap-3 sm:gap-5  items-center">
          <p className="font-[Lilita One] text-[40px] text-[#F8A529] font-[400] cursor-pointer m-0" style={{fontFamily: 'Lilita One'}}>Silver oak</p>
          
        </div>
        
    </div>


        <Breadcrums/>
      <>
        {/*<section>
          <div className="scopepart">
            <div className="container">
              <div className="row">
                <div className="col-md-9">
                  <div className="scopefullpart">
                    <div className="scopeHeading">
                      <h2>Scope &amp; Pricing</h2>
                    </div>
                    <div className="scopePara">
                      <p>Offer Packages</p>
                    </div>
                    <div className="scopePara1">
                      <p>
                        The scope of this service is delivers buyers a
                        functional website. package prices should include
                        predefined options at a minimum of $80.Please move your
                        Gig to another category if you do not offer this
                        specific service.
                      </p>
                    </div>
                    <div className="packagePart">
                      <p>Packages</p>
                    </div>
                    <div className="forTable">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>sssss</th>
                            <th>BASIC</th>
                            <th>STANDARD</th>
                            <th>PREMUM</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td />
                            <td>Name Your Package</td>
                            <td>Name Your Package</td>
                            <td>Name Your Package</td>
                          </tr>
                          <tr>
                            <td />
                            <td>Describe the details of your offering</td>
                            <td>Describe the details of your offering</td>
                            <td>Describe the details of your offering</td>
                          </tr>
                          <tr>
                            <td />
                            <td>Delivery Time</td>
                            <td>Delivery Time</td>
                            <td>Delivery Time</td>
                          </tr>
                          <tr>
                            <td>Functional Website</td>
                          
                            <td>
                              <span>
                                <i className="fa-solid fa-square-check bgCheck" />
                              </span>
                            </td>
                            <td>
                              <span>
                                <i className="fa-solid fa-square-check bgCheck" />
                              </span>
                            </td>
                            <td>
                              <span>
                                <i className="fa-solid fa-square-check bgCheck" />
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>Number of pages</td>
                            <td>
                              <select name="" id="custWidth">
                                <option value="">1</option>
                                <option value="">2</option>
                                <option value="">3</option>
                                <option value="">4</option>
                              </select>
                            </td>
                            <td>
                              <select name="" id="custWidth">
                                <option value="">1</option>
                                <option value="">2</option>
                                <option value="">3</option>
                                <option value="">4</option>
                              </select>
                            </td>
                            <td>
                              <select name="" id="custWidth">
                                <option value="">1</option>
                                <option value="" selected="">
                                  2
                                </option>
                                <option value="">3</option>
                                <option value="">4</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>Revisions</td>
                            <td>
                              <select name="" id="custWidth">
                                <option value="">1</option>
                                <option value="">2</option>
                                <option value="">3</option>
                                <option value="">4</option>
                              </select>
                            </td>
                            <td>
                              <select name="" id="custWidth">
                                <option value="">1</option>
                                <option value="">2</option>
                                <option value="">3</option>
                                <option value="">4</option>
                              </select>
                            </td>
                            <td>
                              <select name="" id="custWidth">
                                <option value="">1</option>
                                <option value="" selected="">
                                  2
                                </option>
                                <option value="">3</option>
                                <option value="">4</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>Responsive Design</td>
                           
                            <td>
                              <span>
                                <i className="fa-solid fa-square-check bgCheck" />
                              </span>
                            </td>
                            <td>
                              <span>
                                <i className="fa-solid fa-square-check bgCheck" />
                              </span>
                            </td>
                            <td>
                              <span>
                                <i className="fa-solid fa-square-check bgCheck" />
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>Plugins/extensions installation</td>
                            <td>
                              <select name="" id="custWidth">
                                <option value="">1</option>
                                <option value="">2</option>
                                <option value="">3</option>
                                <option value="">4</option>
                              </select>
                            </td>
                            <td>
                              <select name="" id="custWidth">
                                <option value="">1</option>
                                <option value="">2</option>
                                <option value="">3</option>
                                <option value="">4</option>
                              </select>
                            </td>
                            <td>
                              <select name="" id="custWidth">
                                <option value="">1</option>
                                <option value="" selected="">
                                  2
                                </option>
                                <option value="">3</option>
                                <option value="">4</option>
                              </select>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="rightSideScope">
                    <div className="rightSideScope1">
                      <ul>
                        <li>Set the prices for your 3 packages.</li>
                        <li>
                          Select the elements you want to include in each offer.
                        </li>
                        <li>Add Extras to increase you order value.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
                    </section>*/}

        <section>
          <div className="container ">
            <div className="row">
              <div className="col-md-8">
                <div className="text-center mt-5">
                  <h4>
                    {" "}
                    <b>Scope & Pricing</b>
                  </h4>
                </div>

                <div className="text-center bg-slate-200 px-auto mt-3">
                  <p className="py-3 px-5">
                    <b>
                      The scope of this service is delivers buyers a functional
                      website. package prices should include predefined options
                      at a minimum of $80.Please move your Gig to another
                      category if you do not offer this specific service.
                    </b>
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-8">
                <div className="text-start">
                  <h4>
                    <b>Packages</b>
                  </h4>
                </div>

                <div className="mt-4">
                  <table class="min-w-full border-collapse block md:table">
                    <thead class="block md:table-header-group">
                      <tr class="border  border-grey-500  md:border-none block md:table-row absolute -top-full md:top-auto -left-full md:left-auto  md:relative ">
                        <th class="bg-[#F7F7F7] p-2 text-black  font-bold md:border md:border-grey-500 text-left block md:table-cell"></th>
                        <th class="bg-[#F7F7F7] p-2 text-black font-bold md:border md:border-grey-500 text-left block md:table-cell">
                          Basic
                        </th>
                        <th class="bg-[#F7F7F7] p-2 text-black font-bold md:border md:border-grey-500 text-left block md:table-cell">
                          Standard
                        </th>
                        <th class="bg-[#F7F7F7] p-2 text-black font-bold md:border md:border-grey-500 text-left block md:table-cell">
                          Premium
                        </th>
                      </tr>
                    </thead>
                    <tbody class="block md:table-row-group">
                      <tr class="bg-white border border-grey-500 md:border-none block md:table-row">
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell"></td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          NAME YOUR PACKAGE
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          NAME YOUR PACKAGE
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          NAME YOUR PACKAGE
                        </td>
                      </tr>
                      <tr class="bg-white border border-grey-500 md:border-none block md:table-row">
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <span class="inline-block w-1/3 md:hidden font-bold">
                            Name
                          </span>
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          Describe the details of your offering
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          Describe the details of your offering
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          Describe the details of your offering
                        </td>
                      </tr>

                      <tr class="bg-white border border-grey-500 md:border-none block md:table-row">
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell"></td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <span>
                            <i class="fa-solid fa-square-check bgCheck"></i>
                          </span>
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <span>
                            <i class="fa-solid fa-square-check bgCheck"></i>
                          </span>
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <span>
                            <i class="fa-solid fa-square-check bgCheck"></i>
                          </span>
                        </td>
                      </tr>

                      <tr class="bg-white border border-grey-500 md:border-none block md:table-row">
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          Functional website
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <select name="" id="custWidth">
                            <option value="">1</option>
                            <option value="">2</option>
                            <option value="">3</option>
                            <option value="">4</option>
                          </select>
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <select name="" id="custWidth">
                            <option value="">1</option>
                            <option value="">2</option>
                            <option value="">3</option>
                            <option value="">4</option>
                          </select>
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <select name="" id="custWidth">
                            <option value="">1</option>
                            <option value="">2</option>
                            <option value="">3</option>
                            <option value="">4</option>
                          </select>
                        </td>
                      </tr>

                      <tr class="bg-white border border-grey-500 md:border-none block md:table-row">
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          Number of pages
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <select name="" id="custWidth">
                            <option value="">1</option>
                            <option value="">2</option>
                            <option value="">3</option>
                            <option value="">4</option>
                          </select>
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <select name="" id="custWidth">
                            <option value="">1</option>
                            <option value="">2</option>
                            <option value="">3</option>
                            <option value="">4</option>
                          </select>
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <select name="" id="custWidth">
                            <option value="">1</option>
                            <option value="">2</option>
                            <option value="">3</option>
                            <option value="">4</option>
                          </select>
                        </td>
                      </tr>
                      <tr class="bg-white border border-grey-500 md:border-none block md:table-row">
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          Revisions
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <span>
                            <i class="fa-solid fa-square-check bgCheck"></i>
                          </span>
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <span>
                            <i class="fa-solid fa-square-check bgCheck"></i>
                          </span>
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <span>
                            <i class="fa-solid fa-square-check bgCheck"></i>
                          </span>
                        </td>
                      </tr>
                      <tr class="bg-white border border-grey-500 md:border-none block md:table-row">
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          Responsive design
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <select name="" id="custWidth">
                            <option value="">1</option>
                            <option value="">2</option>
                            <option value="">3</option>
                            <option value="">4</option>
                          </select>
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <select name="" id="custWidth">
                            <option value="">1</option>
                            <option value="">2</option>
                            <option value="">3</option>
                            <option value="">4</option>
                          </select>
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <select name="" id="custWidth">
                            <option value="">1</option>
                            <option value="">2</option>
                            <option value="">3</option>
                            <option value="">4</option>
                          </select>
                        </td>
                      </tr>
                      <tr class="bg-white border border-grey-500 md:border-none block md:table-row">
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          Content upload
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <span>
                            <i class="fa-solid fa-square-check bgCheck"></i>
                          </span>
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <span>
                            <i class="fa-solid fa-square-check bgCheck"></i>
                          </span>
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <span>
                            <i class="fa-solid fa-square-check bgCheck"></i>
                          </span>
                        </td>
                      </tr>
                      <tr class="bg-white border border-grey-500 md:border-none block md:table-row">
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          Plugins/extensions installation
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-center block md:table-cell">
                          Select
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-center block md:table-cell">
                          Select
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-center block md:table-cell">
                          Select
                        </td>
                      </tr>
                      <tr class="bg-white border border-grey-500 md:border-none block md:table-row">
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          E-commerce functionality
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <span>
                            <i class="fa-solid fa-square-check bgCheck"></i>
                          </span>
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <span>
                            <i class="fa-solid fa-square-check bgCheck"></i>
                          </span>
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <span>
                            <i class="fa-solid fa-square-check bgCheck"></i>
                          </span>
                        </td>
                      </tr>
                      <tr class="bg-white border border-grey-500 md:border-none block md:table-row">
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          Number of products
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <span>
                            <i class="fa-solid fa-square-check bgCheck"></i>
                          </span>
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <span>
                            <i class="fa-solid fa-square-check bgCheck"></i>
                          </span>
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <span>
                            <i class="fa-solid fa-square-check bgCheck"></i>
                          </span>
                        </td>
                      </tr>
                      <tr class="bg-white border border-grey-500 md:border-none block md:table-row">
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          Payment processing
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <span>
                            <i class="fa-solid fa-square-check bgCheck"></i>
                          </span>
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <span>
                            <i class="fa-solid fa-square-check bgCheck"></i>
                          </span>
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <span>
                            <i class="fa-solid fa-square-check bgCheck"></i>
                          </span>
                        </td>
                      </tr>
                      <tr class="bg-white border border-grey-500 md:border-none block md:table-row">
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          Opt-in from
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <span>
                            <i class="fa-solid fa-square-check bgCheck"></i>
                          </span>
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <span>
                            <i class="fa-solid fa-square-check bgCheck"></i>
                          </span>
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <span>
                            <i class="fa-solid fa-square-check bgCheck"></i>
                          </span>
                        </td>
                      </tr>
                      <tr class="bg-white border border-grey-500 md:border-none block md:table-row">
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          Autoresponder integration
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <span>
                            <i class="fa-solid fa-square-check bgCheck"></i>
                          </span>
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <span>
                            <i class="fa-solid fa-square-check bgCheck"></i>
                          </span>
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <span>
                            <i class="fa-solid fa-square-check bgCheck"></i>
                          </span>
                        </td>
                      </tr>
                      <tr class="bg-white border border-grey-500 md:border-none block md:table-row">
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          Speed optimization
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <span>
                            <i class="fa-solid fa-square-check bgCheck"></i>
                          </span>
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <span>
                            <i class="fa-solid fa-square-check bgCheck"></i>
                          </span>
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <span>
                            <i class="fa-solid fa-square-check bgCheck"></i>
                          </span>
                        </td>
                      </tr>
                      <tr class="bg-white border border-grey-500 md:border-none block md:table-row">
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          Social media icon
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <span>
                            <i class="fa-solid fa-square-check bgCheck"></i>
                          </span>
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <span>
                            <i class="fa-solid fa-square-check bgCheck"></i>
                          </span>
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          <span>
                            <i class="fa-solid fa-square-check bgCheck"></i>
                          </span>
                        </td>
                      </tr>
                      <tr class="bg-white border border-grey-500 md:border-none block md:table-row">
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          Price
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          $110
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          $140
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          $200
                        </td>
                      </tr>
                      <tr class="bg-white border border-grey-500 md:border-none block md:table-row">
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          Shutterstock media
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell"></td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell"></td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          powered by Shutterstock
                        </td>
                      </tr>
                      <tr class="bg-white border border-grey-500 md:border-none block md:table-row">
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          Licensed images
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          2($4)
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          2($4)
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          2($16)
                        </td>
                      </tr>
                      <tr class="bg-white border border-grey-500 md:border-none block md:table-row">
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          Total (price)
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          $114
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          $144
                        </td>
                        <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                          $216
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="mt-5">
                  <h5>
                    <b>Add extra fast services</b>
                  </h5>
                </div>

                <div>
                  <input
                    type="text"
                    id="disabled-input"
                    aria-label="disabled input"
                    class="mb-6 bg-gray-100 border border-gray-300 text-sky-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 cursor-not-allowed dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-sky-700 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    value="Extra fast services"
                    disabled
                  />
                </div>

                <div class="relative overflow-x-auto ">
                  <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-white uppercase bg-[#05277D] dark:bg-[#05277D] dark:text-white">
                      <tr>
                        <th
                          scope="col"
                          class="px-6 py-3 rounded-s-lg bg-[#05277D] text-white"
                        >
                          BASIC
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 bg-[#05277D] bg-[#05277D]"
                        >
                          I’ll deliver in only
                          <select name="" id="custWidth2">
                            <option value="">1 Day </option>
                            <option value="">2</option>
                            <option value="">3</option>
                            <option value="">4</option>
                          </select>
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 rounded-e-lg bg-[#05277D]"
                        >
                          for an extra
                          <select name="" id="custWidth2">
                            <option value="">14 Day </option>
                            <option value=""></option>
                            <option value="">3</option>
                            <option value="">4</option>
                          </select>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="bg-white dark:bg-gray-800 text-white">
                        <th
                          scope="row"
                          class="px-6 py-4 font-medium text-white whitespace-nowrap dark: text-white bg-[#05277D]"
                        >
                          STANDARD
                        </th>
                        <td class="px-6 py-4 bg-[#05277D]">
                          {" "}
                          I’ll deliver in only
                          <select name="" id="custWidth2">
                            <option value="">3 Day </option>
                            <option value="">2</option>
                            <option value="">3</option>
                            <option value="">4</option>
                          </select>
                        </td>
                        <td class="px-6 py-4 bg-[#05277D] ">
                          for an extra
                          <select name="" id="custWidth2">
                            <option value="">14 Day </option>
                            <option value=""></option>
                            <option value="">3</option>
                            <option value="">4</option>
                          </select>
                        </td>
                      </tr>
                      <tr class="bg-white dark:bg-gray-800">
                        <th
                          scope="row"
                          class="px-6 py-4 font-medium text-white whitespace-nowrap dark:text-white bg-[#05277D] "
                        >
                          PREMIUM
                        </th>
                        <td class="px-6 py-4 bg-[#05277D] text-white  ">
                          I’ll deliver in only
                          <select name="" id="custWidth2">
                            <option value="">14 Day </option>
                            <option value=""></option>
                            <option value="">3</option>
                            <option value="">4</option>
                          </select>
                        </td>
                        <td class="px-6 py-4 bg-[#05277D] text-white ">
                          for an extra
                          <select name="" id="custWidth2">
                            <option value="">14 Day </option>
                            <option value=""></option>
                            <option value="">3</option>
                            <option value="">4</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="mt-3">
                  <input
                    type="text"
                    id="disabled-input"
                    aria-label="disabled input"
                    class="mb-6 bg-gray-100 border border-gray-300 
                    text-gray-900 text-sm rounded-lg focus:ring-blue-500 
                    focus:border-blue-500 block w-full p-2.5 cursor-not-allowed dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    value="Additional page"
                    disabled
                  />

                  <input
                    type="text"
                    id="disabled-input"
                    aria-label="disabled input"
                    class="mb-6 bg-gray-100 border border-gray-300 
                    text-gray-900 text-sm rounded-lg focus:ring-blue-500 
                    focus:border-blue-500 block w-full p-2.5 cursor-not-allowed dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    value="Additional revision"
                    disabled
                  />

                  <input
                    type="text"
                    id="disabled-input"
                    aria-label="disabled input"
                    class="mb-6 bg-gray-100 border border-gray-300 
                    text-gray-900 text-sm rounded-lg focus:ring-blue-500 
                    focus:border-blue-500 block w-full p-2.5 cursor-not-allowed dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    value="Additional page"
                    disabled
                  />

                  <input
                    type="text"
                    id="disabled-input"
                    aria-label="disabled input"
                    class="mb-6 bg-gray-100 border border-gray-300 
                    text-gray-900 text-sm rounded-lg focus:ring-blue-500 
                    focus:border-blue-500 block w-full p-2.5 cursor-not-allowed dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    value="Additional revision"
                    disabled
                  />
                  <input
                    type="text"
                    id="disabled-input"
                    aria-label="disabled input"
                    class="mb-6 bg-gray-100 border border-gray-300 
                    text-gray-900 text-sm rounded-lg focus:ring-blue-500 
                    focus:border-blue-500 block w-full p-2.5 cursor-not-allowed dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    value="Additional plugin installation"
                    disabled
                  />
                  <input
                    type="text"
                    id="disabled-input"
                    aria-label="disabled input"
                    class="mb-6 bg-gray-100 border border-gray-300 
                    text-gray-900 text-sm rounded-lg focus:ring-blue-500 
                    focus:border-blue-500 block w-full p-2.5 cursor-not-allowed dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    value="E-commerce functionality"
                    disabled
                  />
                </div>
                <div className="text-[#2848F1]">
                    <span>+ Add Gig Extra</span>
                </div>
                <div className="text-center">
                <Link to="/ProDescription">
                <button className="btn btn-xs sm:btn-sm md:btn-md lg:btn-lg btn-primary">Save & Continue</button></Link>
                </div>

                <></>
              </div>
              <div className="col-md-4 mt-5">
                <div class="text-white py-10 bg-[#4EAD03]">
                  <ul class="list-disc">
                    <li>Set the prices for your 3 packages.</li>
                    <li>
                      {" "}
                      Select the elements you want to include in each offer.
                    </li>
                    <li>Add Extras to increase you order value.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="doorStep pb-5">
            <div className="container">
              <div className="row doorStepBack">
                <div className="col-md-12 ">
                  <div className="doorStepof">
                    <h5>Door Step of Freelance services !</h5>
                  </div>
                  <div className="doorStepofbtn">
                    <a href="javascript:void(0)">Join Silver oak</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <hr />
      </>
    </div>
  );
};

export default ProfessionalTwoBody;
