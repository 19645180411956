import React from "react";
import begibimg from "../images/computer_10049811 1.png";
import { Link } from "react-router-dom";
import "@fontsource/lilita-one";
const BegibBody = () => {
  return (
    <div>
      {/* header */}
      <div className="px-4 py-1 flex justify-between items-center flex-wrap border-b border-black">
        <div className="flex gap-3 sm:gap-5  items-center">
          <Link to="/">
            <p
              className="font-[Lilita One] text-[40px] text-[#F8A529] font-[400] cursor-pointer"
              style={{ fontFamily: "Lilita One" }}
            >
              Silver oak
            </p>
          </Link>
        </div>
        <div className="flex items-center gap-3 ">
          <div className="flex gap-2 items-center">
            <div className="text-[18px] font-[600] cursor-pointer h-[23px] w-[23px] bg-[#0C2563] text-white rounded-[50%] flex items-center justify-center ">
              1
            </div>
            <div className="text-[17px] font-[600] text-[#494949] ">
              share brief description
            </div>
          </div>
          <div className="flex gap-2 items-center">
            <div className="text-[18px] font-[600] cursor-pointer h-[23px] w-[23px] bg-[#769BFB] text-white rounded-[50%] flex items-center justify-center ">
              2
            </div>
            <div className="text-[17px] font-[600] text-[#494949] ">
              Add timeline and budget{" "}
            </div>
          </div>
        </div>
        <div className="flex gap-3 items-center">
          <div className="text-[18px] font-[600] cursor-pointer ">Exit</div>
        </div>
      </div>
      <>
        <div className="secondLayout">
          <div className="container">
            <div className="row mt-5">
              <div className="col-md-4">
                <div className="leftSides">
                  <h1>Let the matching begib...</h1>
                  <p className="text-secondary">
                    This is where you fill us in on the big picture.
                  </p>
                  <a href="#" className="text-secondary">
                    How does this matching thing work?
                  </a>
                  <img src={begibimg} width={200} className="img-fluid" />
                </div>
              </div>
              <div className="col-md-8">
                <div className="rightSides">
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Are you a freelancer?"
                      readOnly=""
                    />
                    <div className="mt-3 rightSidesTxt">
                      <h6>Give your project brief a title</h6>
                      <p>
                        Keep it short and simple this will help us match you to
                        the right category.
                      </p>
                    </div>
                    <div className="input-group mb-3 rightSidesTxt2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Example: Create a WordPress website for my company"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                      />
                      <span className="input-group-text" id="basic-addon2">
                        0/70
                      </span>
                    </div>
                    <span className="rightHyper">
                      <a href="#">Some title example</a>
                    </span>
                    <div className="rightSidesTxt3 mb-3 mt-5">
                      <label
                        htmlFor="exampleFormControlTextarea1"
                        className="form-label"
                      >
                        <h6>What are you looking to get done?</h6>
                        <p className="text-secondary">
                          This will help get your brief to the right talent.
                          Specifics help here.
                        </p>
                      </label>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows={3}
                        placeholder="I Need..."
                        defaultValue={""}
                      />
                      <div className="howToWrite mt-3">
                        <a href="#">How to write a great description</a>
                      </div>
                    </div>

                    <div>
                      <button
                        className="btn bg-secondary text-white"
                        id="file_input"
                        type="file"
                      >
                        <i className="fa-solid fa-link" />
                        Attach files
                      </button>
                    </div>

                    <div className="mt-3">
                      <form class="max-w-sm ">
                        <label
                          for="countries"
                          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          {" "}
                          Which category best fits your project?
                        </label>
                        <select
                          id="countries"
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          <option selected>Choose 1 categgory</option>
                          <option value="US">United States</option>
                          <option value="CA">Canada</option>
                          <option value="FR">France</option>
                          <option value="DE">Germany</option>
                        </select>
                      </form>
                    </div>
                    <h5
                      style={{
                        fontWeight: 600,
                        fontSize: 15,
                        marginTop: 40,
                        marginBottom: 20,
                      }}
                    >
                      Upgrade with popular extras (Optional)
                    </h5>
                    <div className="mt-5 d-flex justify-content-end">
                      <Link to="/TimingBudget">
                        <button type="button" className="btn btn-primary">
                          Continue
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section>
          <div className="doorStep pb-5">
            <div className="container">
              <div className="row doorStepBack">
                <div className="col-md-12 ">
                  <div className="doorStepof">
                    <h5>Door Step of Freelance services !</h5>
                  </div>
                  <div className="doorStepofbtn">
                    <a href="javascript:void(0)">Join Silver oak</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <hr />
      </>
    </div>
  );
};

export default BegibBody;
