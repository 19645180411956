import React from "react";
import Breadcrums from "./Breadcrums";
import { Link } from "react-router-dom";
import '@fontsource/lilita-one';
const ProgalleryBody = () => {
  return (
    <div>
       {/* header */}
    <div className="px-4 py-1 flex justify-between items-center flex-wrap border-b border-black">
        <div className="flex gap-3 sm:gap-5  items-center">
          <p className="font-[Lilita One] text-[40px] text-[#F8A529] font-[400] cursor-pointer m-0" style={{fontFamily: 'Lilita One'}}>Silver oak</p>
          
        </div>
        
    </div>
     
      <Breadcrums/>
      

      <section>
        <div className="container mt-3">
          <div className="row ">
            <div className="col-md-8 ">
              <div className="py-5 m-3 ">
                <h5>
                  <b>Showcase Your Services In A Gig Gallery</b>
                </h5>
                <p>
                  Encourage buyers to choose your Gig by featuring a variety of
                  your work
                </p>
              </div>

              <div class="grid grid-cols-1 p-3 justify-center bg-[#F7F7F7] m-3">
                <div
                  class="w-100 p-2 col-span-3 justify-center justify-self-center 
                     text-black  text-lg  "
                >
                  <div>
                    <p>
                      <span>
                        <i class="fa-solid fa-circle-exclamation"></i>
                      </span>
                      To company with Fiverr’s terms of service, make sure to
                      upload only content you either own or you have the
                      peemission or license to use.
                    </p>
                  </div>
                </div>
              </div>

              <div className="ml-3 ">
                <h5 className="underline">
                  <b>Gig image guidelines</b>
                </h5>

                <h5 className="mt-3">
                  <b>Images (up to 3)</b>
                </h5>
                <p>
                  Get noticed by the right buyers with visual examples of your
                  sevices
                </p>
              </div>
              <div class="container mx-auto px-4">
                <section class="pt-8 px-4">
                  <div class="flex flex-wrap -mx-4">
                    <div class="md:w-1/3 px-4 mb-8">
                      <img
                        class="rounded shadow-md"
                        src="https://source.unsplash.com/random/1280x720"
                        alt=""
                      />
                    </div>
                    <div class="md:w-1/3 px-4 mb-8">
                      <img
                        class="rounded shadow-md"
                        src="https://source.unsplash.com/random/1280x720"
                        alt=""
                      />
                    </div>
                    <div class="md:w-1/3 px-4 mb-8">
                      <img
                        class="rounded shadow-md"
                        src="https://source.unsplash.com/random/1280x720"
                        alt=""
                      />
                    </div>
                  </div>
                </section>
              </div>

              <div className="mt-5">
                <h5>
                  <b>Video (one only)</b>
                </h5>
                <p>
                  Capture buyers’ attention with a video showcases your service.
                </p>
                <p>
                  Please choose a video shorter than 75 seconds and smaller than
                  50MB
                </p>
              </div>

              <div className="mt-5">
                <h5>
                  <b>Documents (up to 2)</b>
                </h5>
                <p>
                  Show some of the best work you created in a document (PDFs
                  only).
                </p>
              </div>

              <div className="my-3">
              <Link to="/ProPublish">
                <button className="btn btn-xs sm:btn-sm md:btn-md lg:btn-lg btn-primary">
                  Save & Continue
                </button></Link>
              </div>

              <div className="my-3">
              <Link to="/ProRequire">
                <button className="btn btn-xs sm:btn-sm md:btn-md lg:btn-lg btn-dark ">
                  Back
                </button>
                </Link>
              </div>
            </div>

            <div className="col-md-4">
              <div class="text-white py-10 bg-[#4EAD03] text-center">
                <h5>Provide Your Requirements</h5>
                <div className="p-5">
                  <video className="h-full w-full rounded-lg" controls>
                    <source
                      src="https://docs.material-tailwind.com/demo.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div className="p-3">
                  <p>Indicate what you need before you can start working.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProgalleryBody;
