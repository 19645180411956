import React from 'react'
import ProgalleryBody from '../components/ProgalleryBody'

const ProGallery = () => {
  return (
    <div>
      <ProgalleryBody/>
    </div>
  )
}

export default ProGallery
