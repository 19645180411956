import React from 'react'
import ProfessionalTwoBody from '../components/ProfessionalTwoBody'

const ProfessionalTwo = () => {
  return (
    <div>
      <ProfessionalTwoBody/>
    </div>
  )
}

export default ProfessionalTwo
