import React from 'react'
import ProPublishBody from '../components/ProPublishBody'

const ProPublish = () => {
  return (
    <div>
     <ProPublishBody/> 
    </div>
  )
}

export default ProPublish
