import React from 'react'
import DoneSellerBody from '../components/DoneSellerBody'

const DoneSeller = () => {
  return (
    <div>
      <DoneSellerBody/>
    </div>
  )
}

export default DoneSeller
