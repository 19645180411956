import React from 'react'
import PersonalBusinessBody from '../components/PersonalBusinessBody'

const PersonalBusiness = () => {
  return (
    <div>
      <PersonalBusinessBody/>
    </div>
  )
}

export default PersonalBusiness
