import React from "react";
import Breadcrums from "./Breadcrums";
import { Link } from "react-router-dom";
import '@fontsource/lilita-one';
const ProDescriptionBody = () => {
  return (
    <div>

      {/* header */}
    <div className="px-4 py-1 flex justify-between items-center flex-wrap border-b border-black">
        <div className="flex gap-3 sm:gap-5  items-center">
          <p className="font-[Lilita One] text-[40px] text-[#F8A529] font-[400] cursor-pointer m-0" style={{fontFamily: 'Lilita One'}}>Silver oak</p>
          
        </div>
        
    </div>
      <Breadcrums />

      <section>
        <div className="container mt-3">
          <div className="row">
            <div className="col-md-8">
              <div>
                <h6>
                  <b>Description</b>
                </h6>
                <p className="mt-5">Briefly Describe Your Gig</p>
              </div>

              <form>
                <div class="w-full mb-4 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
                  <div class="flex items-center justify-between px-3 py-2 border-b dark:border-gray-600">
                    <div class="flex flex-wrap items-center divide-gray-200 sm:divide-x sm:rtl:divide-x-reverse dark:divide-gray-600">
                      <button class="ql-bold size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700">
                        <svg
                          class="flex-shrink-0 size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path d="M14 12a4 4 0 0 0 0-8H6v8"></path>
                          <path d="M15 20a4 4 0 0 0 0-8H6v8Z"></path>
                        </svg>
                      </button>
                      <button
                        class="ql-italic size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
                        type="button"
                      >
                        <svg
                          class="flex-shrink-0 size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <line x1="19" x2="10" y1="4" y2="4"></line>
                          <line x1="14" x2="5" y1="20" y2="20"></line>
                          <line x1="15" x2="9" y1="4" y2="20"></line>
                        </svg>
                      </button>

                      <div class="flex items-center space-x-1 rtl:space-x-reverse sm:pe-4">
                        <button
                          type="button"
                          class="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
                        ></button>
                      </div>
                      <div class="flex flex-wrap items-center space-x-1 rtl:space-x-reverse sm:ps-4">
                        <button
                          type="button"
                          class="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
                        >
                          <svg
                            class="w-4 h-4"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 21 18"
                          >
                            <path
                              stroke="currentColor"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M9.5 3h9.563M9.5 9h9.563M9.5 15h9.563M1.5 13a2 2 0 1 1 3.321 1.5L1.5 17h5m-5-15 2-1v6m-2 0h4"
                            />
                          </svg>
                          <span class="sr-only">Add list</span>
                        </button>

                        <button
                          type="button"
                          class="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
                        >
                          <svg
                            class="w-4 h-4"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 21 18"
                          >
                            <path
                              stroke="currentColor"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M9.5 3h9.563M9.5 9h9.563M9.5 15h9.563M1.5 13a2 2 0 1 1 3.321 1.5L1.5 17h5m-5-15 2-1v6m-2 0h4"
                            />
                          </svg>
                          <span class="sr-only">Add list</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="px-4 py-2 bg-white rounded-b-lg dark:bg-gray-800">
                    <label for="editor" class="sr-only">
                      Publish post
                    </label>
                    <textarea
                      id="editor"
                      rows="8"
                      class="block w-full px-0 text-sm text-gray-800 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                      placeholder="Write an article..."
                      required
                    ></textarea>
                  </div>
                </div>
              </form>

              <div>
                <h5>
                  <b>Milestone workflow</b>
                  <span>
                    <button className="btn btn-xs sm:btn-sm md:btn-md lg:btn-lg btn-danger ml-5 px-3">
                      New
                    </button>
                  </span>
                </h5>
                <p>
                  Attract buyers by turning you gig a of milestones-they ; know
                  exactly what to expect and you ‘ll get paid hen each milestone
                  is completes
                </p>
              </div>

              <div className="row  border-b-2 border-dashed border-black">
                <div class="grid grid-cols-1 p-10 justify-center bg-white">
                  <div
                    class="w-75 p-2 col-span-3 justify-center justify-self-center 
                    bg-[#F9F9F9] text-black  text-lg px-5"
                  >
                    <div>
                      <span>+</span>
                      <p>Create a TRMPLATE</p>
                      <p>eXACTLY WHAT YOU NEED.</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row  border-b-2 border-dashed border-black">
                <div class="grid grid-cols-1 p-10 justify-center bg-white">
                  <div
                    class="w-75 p-2 col-span-3 justify-center justify-self-center 
                    bg-[#F9F9F9] text-black  text-lg px-5"
                  >
                    <div>
                      <h5>
                        <b>Setting up a workflow with milestones lets</b>
                      </h5>
                    </div>
                    <div className="mt-3">
                      <h5>
                        <b>Bring in more orders</b>
                      </h5>
                      <p>Buyers enjoy great payment terms for large orders.</p>
                    </div>

                    <div className="mt-3">
                      <h5>
                        <b>Set shared expectations</b>
                      </h5>
                      <p>you and your buyers will always be in step</p>
                    </div>

                    <div className="mt-3">
                      <h5>
                        <b>Benefit from Milestone payments</b>
                      </h5>
                      <p>You get paid for each completed milestone</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row  border-b-2 border-dashed border-black">
                <div class="grid grid-cols-1 p-10 justify-center bg-white">
                  <div
                    class="w-75 p-2 col-span-3 justify-center justify-self-center 
                    bg-[#F9F9F9] text-black  text-lg px-5"
                  >
                    <div>
                      <h5>
                        <b>Frequently Asked Questions</b>
                        <span className="ml-20 text-[#4E3B99]">+ Add FAQ</span>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row  border-b-2 border-dashed border-black">
                <div class="grid grid-cols-1 p-10 justify-center bg-white">
                  <div
                    class="w-75 p-2 col-span-3 justify-center justify-self-center 
                    bg-[#F9F9F9] text-black  text-lg px-5"
                  >
                    <div>
                      <p>Add Questio & Answers for Your Byers</p>
                      <span className=" text-[#4E3B99]">+ Add FAQ</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="my-3">
                <Link to="/ProRequire">
                  <button className="btn btn-xs sm:btn-sm md:btn-md lg:btn-lg btn-primary">
                    Save & Continue
                  </button>
                </Link>
              </div>

              <div className="my-3">
                <Link to="/ProfessionalTwo">
                  <button className="btn btn-xs sm:btn-sm md:btn-md lg:btn-lg btn-dark ">
                    Back
                  </button>
                </Link>
              </div>
            </div>

            <div className="col-md-4">
              <div class="text-white py-10 bg-[#4EAD03] text-center">
                <h5>write Your Description & FAQ</h5>
                <div className="p-5">
                  <video className="h-full w-full rounded-lg" controls>
                    <source
                      src="https://docs.material-tailwind.com/demo.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div className="p-3">
                  <p>include the most important information for your gig</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProDescriptionBody;
