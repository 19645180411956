import React from "react";
import { Link } from "react-router-dom";
import '@fontsource/lilita-one';

const ProfessionaloneBody = () => {
  return (
    <div>
      <>
       {/* header */}
    <div className="px-4 py-1 flex justify-between items-center flex-wrap border-b border-black">
        <div className="flex gap-3 sm:gap-5  items-center">
          <Link to='/'>
          <p className="font-[Lilita One] text-[40px] text-[#F8A529] font-[400] cursor-pointer m-0" style={{fontFamily: 'Lilita One'}}>Silver oak</p>
          </Link>
        </div>
        
    </div>

        <section>
          <div className="container mt-5">
            <div className="row">
              <div className="col-lg-12">
                <nav class="flex" aria-label="Breadcrumb">
                  <ol class="inline-flex flex-wrap  items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                    <li class="inline-flex items-center">
                      {/*<a
                        href="#"
                        class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
                      >
                      
                        Home
                      </a>*/}
                      <Link to="/Professionalone">
                        <span className="bgColorr">1</span>Overview
                      </Link>
                    </li>
                    <li>
                      <div class="flex items-center">
                        <svg
                          class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 6 10"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m1 9 4-4-4-4"
                          />
                        </svg>
                        <Link to="/ProfessionalTwo">
                          <span className="bgColorr">2</span>Pricing
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div class="flex items-center">
                        <svg
                          class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 6 10"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m1 9 4-4-4-4"
                          />
                        </svg>
                        <Link to="/ProDescription">
                          <span className="bgColorr">3</span>Description & FAQ
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div class="flex items-center">
                        <svg
                          class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 6 10"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m1 9 4-4-4-4"
                          />
                        </svg>
                        <Link to="/ProRequire">
                          <span className="bgColorr">4</span>Requirements
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div class="flex items-center">
                        <svg
                          class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 6 10"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m1 9 4-4-4-4"
                          />
                        </svg>
                        <Link to="/Gallery">
                          <span className="bgColorr">5</span>Gallery
                        </Link>
                      </div>
                    </li>
                    <li aria-current="page">
                      <div class="flex items-center">
                        <svg
                          class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 6 10"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m1 9 4-4-4-4"
                          />
                        </svg>
                        <Link to="/ProPublish">
                          <span className="bgColorr">6</span>Publish
                        </Link>
                        {/* <span class="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">
                        Publish
                        </span>*/}
                      </div>
                    </li>
                    <li>
                      <button
                        type="button"
                        class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                      >
                        Save
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                      >
                        Save & Preview
                      </button>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="gigPart mt-5">
            <div className="container">
              <div className="row">
                <div className="col-md-3">
                  <div className="gigPartLeft">
                    <div className="gigPartText1">
                      <h5>Gig Title</h5>
                      <p>
                        As your Gig storefront, your title is the most important
                        place to include keywords that buyers would likely use
                        to search for a service like yours
                      </p>
                    </div>

                    <div className="mt-10">
                      <h5>
                        <b>Category</b>
                      </h5>
                      <p>
                        Choose the category and sub- category most suitable for
                        your Gig
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="gigPartMid">
                    <div className="gigPartMid1">
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows={2}
                        placeholder="I will build a responsive business wordpress website design"
                        defaultValue={""}
                      />
                    </div>
                    <div className="gigPartMid2 mt-2">
                      <div className="textareabottom1">
                        <a href="#">Just Perfact!</a>
                      </div>
                      <div className="textareabottom2">
                        <p>52/80 max</p>
                      </div>
                    </div>

                    {/*dropdown code start*/}
                    <div class="pt-20 inline-flex gap-5">
                      <div class="dropdown inline-block relative">
                        <button class="bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center">
                          <span class="mr-1">PROGRAMMING & TECH</span>
                          <svg
                            class="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                          </svg>
                        </button>
                        <ul class="dropdown-menu absolute hidden text-gray-700 pt-1">
                          <li class="">
                            <a
                              class="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                              href="#"
                            >
                              One
                            </a>
                          </li>
                          <li class="">
                            <a
                              class="bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                              href="#"
                            >
                              Two
                            </a>
                          </li>
                          <li class="">
                            <a
                              class="rounded-b bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                              href="#"
                            >
                              Three is the magic number
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div class="dropdown inline-block relative">
                        <button class="bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center">
                          <span class="mr-1">WEBSITE D</span>
                          <svg
                            class="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                          </svg>
                        </button>
                        <ul class="dropdown-menu absolute hidden text-gray-700 pt-1">
                          <li class="">
                            <a
                              class="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                              href="#"
                            >
                              One
                            </a>
                          </li>
                          <li class="">
                            <a
                              class="bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                              href="#"
                            >
                              Two
                            </a>
                          </li>
                          <li class="">
                            <a
                              class="rounded-b bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                              href="#"
                            >
                              Three is the magic number
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    {/*end */}

                    <div className="mt-5">
                      <h6>
                        <b>Suggested categories</b>
                      </h6>
                    </div>
                    <div className="mt-3">
                      <nav class="flex" aria-label="Breadcrumb">
                        <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse ">
                          <li class="d-flex items-center ">
                            <a
                              href="#"
                              class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
                            >
                              Programming & Tech Website Development
                            </a>
                          </li>
                          <li>
                            <div class="flex items-center">
                              <svg
                                class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 6 10"
                              >
                                <path
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="m1 9 4-4-4-4"
                                />
                              </svg>
                              <a
                                href="#"
                                class="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white"
                              >
                                Graphics & Design
                              </a>
                            </div>
                          </li>
                          <li aria-current="page">
                            <div class="flex items-center">
                              <svg
                                class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 6 10"
                              >
                                <path
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="m1 9 4-4-4-4"
                                />
                              </svg>
                              <span class="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">
                                Promamming & Tech Website Maintenance
                              </span>
                            </div>
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="gigPartRight">
                    <div className="gigPartRight1">
                      <h5>Start Defining Your Gig</h5>
                      <div class="p-3">
                        <video className="h-full w-full rounded-lg" controls>
                          <source
                            src="https://docs.material-tailwind.com/demo.mp4"
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      </div>

                      <div class="text-white py-10">
                        <ul class="list-disc">
                          <li>Create a catchy title.</li>
                          <li> Choose a category that fits your Gig</li>
                          <li>
                            Add mota data to help buyers find more Information
                            regarding your Gig{" "}
                          </li>
                          <li>
                            Add tags to help buyers find your Gig while
                            soarching
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container mt-5">
            <div className="row">
              <form class=" max-w-sm">
                <div class="md:flex md:items-center mb-6">
                  <div className="col-md-4">
                    <div class="md:w-1/3">
                      <label
                        class="block text-dark font-bold md:text-right mb-1 md:mb-0 pr-4"
                        for="inline-full-name"
                      >
                        Service Type
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div class="md:w-2/3">
                      <input
                        class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                        id="inline-full-name"
                        type="text"
                        value="WORDPRESS"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>

        <section>
          <div className="container my-5">
            <div className="row">
              <div className="col-md-3">
                <h6>
                  <b>Gig metadata</b>
                </h6>
              </div>
              <div className="col-md-9 inline-flex">
                <div className="col-md-3">
                  <div className="bg-slate-300 p-3">
                    <h5>WEBSITE TYPE *</h5>
                  </div>

                  <div className="p-5">
                    <h5>WEBSITE FEATURS *</h5>
                  </div>

                  <div className="border text-red-600 bg-slate-300 h-75 ">
                    <div className="p-5">
                      <h5>PLUGINS</h5>
                    </div>
                  </div>
                </div>

                <div className="col-md-5">
                  <div className=" pl-5">
                    <h5>Select the features you support</h5>
                  </div>

                  <div class="flex items-center ps-4  rounded dark:border-gray-700">
                    <input
                      id="bordered-checkbox-1"
                      type="checkbox"
                      value=""
                      name="bordered-checkbox"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="bordered-checkbox-1"
                      class="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Default radio
                    </label>
                  </div>
                  <div class="flex items-center ps-4  rounded dark:border-gray-700">
                    <input
                      id="bordered-checkbox-1"
                      type="checkbox"
                      value=""
                      name="bordered-checkbox"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="bordered-checkbox-1"
                      class="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Default radio
                    </label>
                  </div>
                  <div class="flex items-center ps-4  rounded dark:border-gray-700">
                    <input
                      id="bordered-checkbox-1"
                      type="checkbox"
                      value=""
                      name="bordered-checkbox"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="bordered-checkbox-1"
                      class="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Default radio
                    </label>
                  </div>
                  <div class="flex items-center ps-4 rounded dark:border-gray-700">
                    <input
                      id="bordered-checkbox-1"
                      type="checkbox"
                      value=""
                      name="bordered-checkbox"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="bordered-checkbox-1"
                      class="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Default radio
                    </label>
                  </div>
                  <div class="flex items-center ps-4  rounded dark:border-gray-700">
                    <input
                      id="bordered-checkbox-1"
                      type="checkbox"
                      value=""
                      name="bordered-checkbox"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="bordered-checkbox-1"
                      class="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Default radio
                    </label>
                  </div>
                  <div class="flex items-center ps-4  rounded dark:border-gray-700">
                    <input
                      id="bordered-checkbox-1"
                      type="checkbox"
                      value=""
                      name="bordered-checkbox"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="bordered-checkbox-1"
                      class="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Default radio
                    </label>
                  </div>
                  <div class="flex items-center ps-4  rounded dark:border-gray-700">
                    <input
                      id="bordered-checkbox-1"
                      type="checkbox"
                      value=""
                      name="bordered-checkbox"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="bordered-checkbox-1"
                      class="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Default radio
                    </label>
                  </div>
                  <div class="flex items-center ps-4  rounded dark:border-gray-700">
                    <input
                      id="bordered-checkbox-1"
                      type="checkbox"
                      value=""
                      name="bordered-checkbox"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="bordered-checkbox-1"
                      class="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Default radio
                    </label>
                  </div>
                  <div class="flex items-center ps-4  rounded dark:border-gray-700">
                    <input
                      id="bordered-checkbox-1"
                      type="checkbox"
                      value=""
                      name="bordered-checkbox"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="bordered-checkbox-1"
                      class="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Others
                    </label>
                  </div>
                  <div class="flex items-center ps-4  rounded dark:border-gray-700">
                    <input
                      checked
                      id="bordered-checkbox-2"
                      type="checkbox"
                      value=""
                      name="bordered-checkbox"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="bordered-checkbox-2"
                      class="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Checked state
                    </label>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="text-end">
                    <h5>10/10</h5>
                  </div>
                  <div class="flex items-center ps-4  rounded dark:border-gray-700">
                    <input
                      id="bordered-checkbox-1"
                      type="checkbox"
                      value=""
                      name="bordered-checkbox"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="bordered-checkbox-1"
                      class="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Default radio
                    </label>
                  </div>
                  <div class="flex items-center ps-4  rounded dark:border-gray-700">
                    <input
                      checked
                      id="bordered-checkbox-2"
                      type="checkbox"
                      value=""
                      name="bordered-checkbox"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="bordered-checkbox-2"
                      class="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Checked state
                    </label>
                  </div>
                  <div class="flex items-center ps-4  rounded dark:border-gray-700">
                    <input
                      id="bordered-checkbox-1"
                      type="checkbox"
                      value=""
                      name="bordered-checkbox"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="bordered-checkbox-1"
                      class="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Default radio
                    </label>
                  </div>
                  <div class="flex items-center ps-4  rounded dark:border-gray-700">
                    <input
                      id="bordered-checkbox-1"
                      type="checkbox"
                      value=""
                      name="bordered-checkbox"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="bordered-checkbox-1"
                      class="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Default radio
                    </label>
                  </div>
                  <div class="flex items-center ps-4  rounded dark:border-gray-700">
                    <input
                      id="bordered-checkbox-1"
                      type="checkbox"
                      value=""
                      name="bordered-checkbox"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="bordered-checkbox-1"
                      class="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Default radio
                    </label>
                  </div>
                  <div class="flex items-center ps-4  rounded dark:border-gray-700">
                    <input
                      id="bordered-checkbox-1"
                      type="checkbox"
                      value=""
                      name="bordered-checkbox"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="bordered-checkbox-1"
                      class="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Default radio
                    </label>
                  </div>
                  <div class="flex items-center ps-4  rounded dark:border-gray-700">
                    <input
                      id="bordered-checkbox-1"
                      type="checkbox"
                      value=""
                      name="bordered-checkbox"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="bordered-checkbox-1"
                      class="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Default radio
                    </label>
                  </div>
                  <div class="flex items-center ps-4  rounded dark:border-gray-700">
                    <input
                      id="bordered-checkbox-1"
                      type="checkbox"
                      value=""
                      name="bordered-checkbox"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="bordered-checkbox-1"
                      class="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Default radio
                    </label>
                  </div>
                  <div class="flex items-center ps-4  rounded dark:border-gray-700">
                    <input
                      id="bordered-checkbox-1"
                      type="checkbox"
                      value=""
                      name="bordered-checkbox"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="bordered-checkbox-1"
                      class="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Default radio
                    </label>
                  </div>
                  <div class="flex items-center ps-4  rounded dark:border-gray-700">
                    <input
                      id="bordered-checkbox-1"
                      type="checkbox"
                      value=""
                      name="bordered-checkbox"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="bordered-checkbox-1"
                      class="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Default radio
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container mt-20">
            <div className="row">
              <div className="col-md-3">
                <div>
                  <h5>
                    <b>Search tags</b>
                  </h5>
                  <p>
                    Tag your Gig with buzz words that are relevant to the
                    services you offer. Use all 5 tags to get found.
                  </p>
                </div>
              </div>
              <div className="col-md-9">
                <div>
                  <h5>
                    <b>Positive keywords</b>
                  </h5>
                  <p className="text-secondary">
                    Enter search terms you feel your buyers will use when
                    looking for your service.
                  </p>
                </div>

                <div className="border border-black">
                  <div className="row p-3 ">
                    <div className="bg-slate-300 col-md-3 p-2 ml-5">
                      <h6>ADOBE PHOTOSHOP X</h6>
                    </div>

                    <div className="bg-slate-300 col-md-3 ml-5 p-2">
                      <h6>BUILD WEBSITE X</h6>
                    </div>
                    <div className="col-md-3"></div>
                    <div className="col-md-3"></div>
                  </div>
                </div>

                <div className="mt-3">
                  <p>6 tags maamurts. Use letturs and numbers only</p>
                </div>

                <div>
                  <div class="flex items-center">
                    <input
                      id="link-checkbox"
                      type="checkbox"
                      value=""
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="link-checkbox"
                      class="ms-2 text-l font-medium text-gray-900 dark:text-gray-300"
                    >
                      service under applicable laws. I understand that providing
                      licensed services without the required license goes
                      against Fiverr's{" "}
                      <a
                        href="#"
                        class="text-blue-600 dark:text-blue-500 hover:underline"
                      >
                        Community Standards
                      </a>
                      and may result in permanent. suspension of my account.
                    </label>
                  </div>
                </div>

                <div className="mt-5">
                  <p>
                    <span className="text-red-600">Please note:</span> Some
                    categories require that sellers verify their skills
                  </p>
                </div>
              </div>
              <div className="text-end">
              <Link to="/ProfessionalTwo">
                <button
                  class=" select-none font-sans font-bold text-center uppercase
                  transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none
                   text-xs py-3 px-6 rounded-lg bg-primary text-white shadow-md shadow-gray-900/10 
                   hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none
                    active:opacity-[0.85] active:shadow-none"
                  type="button"
                >
                  Save & Continue
                </button>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </>

      <hr />
    </div>
  );
};

export default ProfessionaloneBody;
