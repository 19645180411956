import React from "react";
import sobimg from "../images/sobimg.png";
import img115 from "../images/image 115.png";
import img116 from "../images/image 116.png";
import img118 from "../images/image 118.png";
import img119 from "../images/image 119.png";
import img131 from "../images/image 131.png";
import { Link } from "react-router-dom";

const SellingOnBuyeroneBody = () => {
  return (
    <div>
      {/* header */}
      <div className="px-4 py-1 flex justify-between items-center flex-wrap border-b border-black">
        <div className="flex gap-3 sm:gap-5  items-center">
        <Link to='/'>
          <p
            className="font-[Lilita One] text-[40px] text-[#F8A529] font-[400] cursor-pointer m-0"
            style={{ fontFamily: "Lilita One" }}
          >
            Silver oak
          </p></Link>
        </div>
      </div>

      <div className="container mt-5">
        <div className="row">
          <div className="col-md-3">
            <img src={sobimg} />
          </div>
          <div className="col-md-9 ">
            <div>
              <h5>
                <b>What makes a successful Fiverr profile?</b>
              </h5>
              <p className="text-secondary">
                Your first impression matters! Create a profile that will stand
                out from the crowd on Fiverr.
              </p>
            </div>
            <div className="row mt-3">
              <div className="col-md-3 mt">
                <div>
                  <div className="px-5">
                    {" "}
                    <img src={img115} />
                  </div>
                  <p className="mt-3">
                    Take your time in creating your profile so it's exactly as
                    you want it to be
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="px-5">
                  {" "}
                  <img src={img116} />
                </div>
                <p className="mt-3">
                  Add credibility by linking out to your relevant professional
                  networks.
                </p>
              </div>
              <div className="col-md-3">
                <div className="px-5">
                  {" "}
                  <img src={img118} />
                </div>

                <p className="mt-3">
                  Accurately describe your professional skills to help you get
                  more work.
                </p>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-3">
                <div>
                  <div className="px-5  ">
                    {" "}
                    <img src={img119} />
                  </div>
                  <p className="mt-3">
                    Take your time in creating your profile so it's exactly as
                    you want it to be
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="px-5">
                  {" "}
                  <img src={img131} />
                </div>
                <p className="mt-3">
                  Add credibility by linking out to your relevant professional
                  networks.
                </p>
              </div>
            </div>

            <div className="row justify content center">
              <div>
                <Link to="/SellingOnBuyerTwo">
                  <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Continue
                  </button>
                </Link>
                <Link to="/SellingOnBuyer">
                  <button class="bg-[#555555] hover:bg-black-700 text-white font-bold py-2 px-4 rounded ml-5">
                    Back
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellingOnBuyeroneBody;
