import React from 'react'
import ReviewSellerBody from '../components/ReviewSellerBody'

const ReviewSeller = () => {
  return (
    <div>
      <ReviewSellerBody/>
    </div>
  )
}

export default ReviewSeller
