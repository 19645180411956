import React from 'react'
import AboutBody from '../components/AboutBody'

const About = () => {
  return (
    <div>
      <AboutBody/>
    </div>
  )
}

export default About
