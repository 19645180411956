import React from 'react'
import talentsellerimg1 from '../images/talentsellerimg.png'
import talentsellerimg2 from '../images/talentsellerimg2.png'
import talensellerimg3 from '../images/talentsellerimg3.png'
import talentsellerimg4 from '../images/talentsellerimg4.png'
import talentsellerimg5 from '../images/talentsellerimg5.png'
import talentsellerimg6 from '../images/talentsellerimg6.png'
const Card = () => {
  return (
    <div>
<section>
  <div className="mainPart mt-5">
    <div className="container">
      <div className="row g-0">
        <div className="col-md-6">
          <div className="leftSidePart">
            <div
              className="card customCardBorder me-3"
              style={{ width: "34rem" }}
            >
              <div className="card-body">
                <div className="allItem">
                  <div className="checkBox">
                    <input type="checkbox" id="scales" name="scales" />
                  </div>
                  <div className="userIcon ms-2">
                    <i className="fa-regular fa-user" />
                  </div>
                  <div className="paragraphDiv">
                    <span className="scopic">Scopic</span>
                    <span className="member">Member</span>
                    <a href="#">Get a Quote</a>
                    <br />
                    <span className="USACus">
                      Rutland, Massachusetts, United States
                    </span>
                    <br />
                    <span className="custDoller">
                      <i className="fa-solid fa-dollar-sign" />
                    </span>
                    <span className="customPrice">624,064/YR</span>
                    <span className="customLike">
                      <i className="fa-regular fa-thumbs-up" /> 100%
                    </span>
                    <span className="customHeart">
                      <i className="fa-regular fa-heart" />
                    </span>
                  </div>
                  <div className="horizontalLine mt-3" />
                </div>
                <div className="allItem1 mt-2">
                  <div className="imgSection">
                    <img
                      src={talentsellerimg2}
                      className="img-fluid"
                    />
                  </div>
                  <div className="textSectioncus">
                    <span className="custtext1 mt-2">
                      Web Application Development
                    </span>
                    <span className="custDoller1">
                      <i className="fa-solid fa-dollar-sign ms-2" />
                      38/hr
                    </span>
                    <span className="custStart">Starting at $1,000</span>
                    <br />
                  </div>
                  <div className="textSectioncus1">
                    <p>
                      Scopic offers high-quality and affordable web development
                      and design services, providing customized solutions that
                      best fit your business's unique needs. Having over 15
                      years of experience in the soft
                    </p>
                  </div>
                </div>
                <div className="allAppitem">
                  <span>
                    <a href="#">Programming &amp; Development</a>
                  </span>
                  <span>
                    <a href="#">Programming &amp; Software</a>
                  </span>
                  <span>
                    <a href="#">Angular</a>
                  </span>
                  <span className="cusTop">
                    <a href="#">API</a>
                  </span>
                  <span className="cusTop">
                    <a href="#">ASP.Net</a>
                  </span>
                  <span className="cusTop">
                    <a href="#">AWS</a>
                  </span>
                  <br />
                  <span className="cusTop">
                    <a href="#">Cloud Computing</a>
                  </span>
                </div>
                <div className="allportfolliowItem mt-3">
                  <div className="portfolioItem me-2 mt-2">
                    <span className="portCusIcon">
                      <i className="fa-solid fa-link" />
                    </span>
                    <span className="cusMoreService">
                      <a href="#">More Services(19)</a>
                    </span>
                    <span className="CustomLine" />
                  </div>
                  <div className="portfolioItem mt-2">
                    <span className="portCusIcon">
                      <i className="fa-solid fa-image" />
                    </span>
                    <span className="cusMoreService">
                      <a href="#">Portfolio(30)</a>
                    </span>
                    <span className="CustomLine" />
                  </div>
                  <div className="portfolioItem me-2 mt-2 ms-2">
                    <span className="portCusIcon">
                      <i className="fa-regular fa-thumbs-up" />
                    </span>
                    <span className="cusMoreService">
                      <a href="#">Performance</a>
                    </span>
                    <span className="CustomLine" />
                  </div>
                  <div className="portfolioItem mt-2">
                    <span className="portCusIcon">
                      <i className="fa-solid fa-user" />
                    </span>
                    <span className="cusMoreService">
                      <a href="#">About</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="rightSidePart">
            <div
              className="card customCardBorder me-3"
              style={{ width: "34rem" }}
            >
              <div className="card-body">
                <div className="allItem">
                  <div className="checkBox">
                    <input type="checkbox" id="scales" name="scales" />
                  </div>
                  <div className="userIcon ms-2">
                    <i className="fa-regular fa-user" />
                  </div>
                  <div className="paragraphDiv">
                    <span className="scopic">Scopic</span>
                    <span className="member">Member</span>
                    <a href="#">Get a Quote</a>
                    <br />
                    <span className="USACus">
                      Rutland, Massachusetts, United States
                    </span>
                    <br />
                    <span className="custDoller">
                      <i className="fa-solid fa-dollar-sign" />
                    </span>
                    <span className="customPrice">624,064/YR</span>
                    <span className="customLike">
                      <i className="fa-regular fa-thumbs-up" /> 100%
                    </span>
                    <span className="customHeart">
                      <i className="fa-regular fa-heart" />
                    </span>
                  </div>
                  <div className="horizontalLine mt-3" />
                </div>
                <div className="allItem1 mt-2">
                  <div className="imgSection">
                    <img
                      src={talentsellerimg1}
                      className="img-fluid"
                    />
                  </div>
                  <div className="textSectioncus">
                    <span className="custtext1 mt-2">
                      Web Application Development
                    </span>
                    <span className="custDoller1">
                      <i className="fa-solid fa-dollar-sign ms-2" />
                      38/hr
                    </span>
                    <span className="custStart">Starting at $1,000</span>
                    <br />
                  </div>
                  <div className="textSectioncus1">
                    <p>
                      Scopic offers high-quality and affordable web development
                      and design services, providing customized solutions that
                      best fit your business's unique needs. Having over 15
                      years of experience in the soft
                    </p>
                  </div>
                </div>
                <div className="allAppitem">
                  <span>
                    <a href="#">Programming &amp; Development</a>
                  </span>
                  <span>
                    <a href="#">Programming &amp; Software</a>
                  </span>
                  <span>
                    <a href="#">Angular</a>
                  </span>
                  <span className="cusTop">
                    <a href="#">API</a>
                  </span>
                  <span className="cusTop">
                    <a href="#">ASP.Net</a>
                  </span>
                  <span className="cusTop">
                    <a href="#">AWS</a>
                  </span>
                  <br />
                  <span className="cusTop">
                    <a href="#">Cloud Computing</a>
                  </span>
                </div>
                <div className="allportfolliowItem mt-3">
                  <div className="portfolioItem me-2 mt-2">
                    <span className="portCusIcon">
                      <i className="fa-solid fa-link" />
                    </span>
                    <span className="cusMoreService">
                      <a href="#">More Services(19)</a>
                    </span>
                    <span className="CustomLine" />
                  </div>
                  <div className="portfolioItem mt-2">
                    <span className="portCusIcon">
                      <i className="fa-solid fa-image" />
                    </span>
                    <span className="cusMoreService">
                      <a href="#">Portfolio(30)</a>
                    </span>
                    <span className="CustomLine" />
                  </div>
                  <div className="portfolioItem me-2 mt-2 ms-2">
                    <span className="portCusIcon">
                      <i className="fa-regular fa-thumbs-up" />
                    </span>
                    <span className="cusMoreService">
                      <a href="#">Performance</a>
                    </span>
                    <span className="CustomLine" />
                  </div>
                  <div className="portfolioItem mt-2">
                    <span className="portCusIcon">
                      <i className="fa-solid fa-user" />
                    </span>
                    <span className="cusMoreService">
                      <a href="#">About</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row g-0 mt-4">
        <div className="col-md-6">
          <div className="leftSidePart">
            <div
              className="card customCardBorder me-3"
              style={{ width: "34rem" }}
            >
              <div className="card-body">
                <div className="allItem">
                  <div className="checkBox">
                    <input type="checkbox" id="scales" name="scales" />
                  </div>
                  <div className="userIcon ms-2">
                    <i className="fa-regular fa-user" />
                  </div>
                  <div className="paragraphDiv">
                    <span className="scopic">Scopic</span>
                    <span className="member">Member</span>
                    <a href="#">Get a Quote</a>
                    <br />
                    <span className="USACus">
                      Rutland, Massachusetts, United States
                    </span>
                    <br />
                    <span className="custDoller">
                      <i className="fa-solid fa-dollar-sign" />
                    </span>
                    <span className="customPrice">624,064/YR</span>
                    <span className="customLike">
                      <i className="fa-regular fa-thumbs-up" /> 100%
                    </span>
                    <span className="customHeart">
                      <i className="fa-regular fa-heart" />
                    </span>
                  </div>
                  <div className="horizontalLine mt-3" />
                </div>
                <div className="allItem1 mt-2">
                  <div className="imgSection">
                    <img
                      src={talentsellerimg4}
                      className="img-fluid"
                    />
                  </div>
                  <div className="textSectioncus">
                    <span className="custtext1 mt-2">
                      Web Application Development
                    </span>
                    <span className="custDoller1">
                      <i className="fa-solid fa-dollar-sign ms-2" />
                      38/hr
                    </span>
                    <span className="custStart">Starting at $1,000</span>
                    <br />
                  </div>
                  <div className="textSectioncus1">
                    <p>
                      Scopic offers high-quality and affordable web development
                      and design services, providing customized solutions that
                      best fit your business's unique needs. Having over 15
                      years of experience in the soft
                    </p>
                  </div>
                </div>
                <div className="allAppitem">
                  <span>
                    <a href="#">Programming &amp; Development</a>
                  </span>
                  <span>
                    <a href="#">Programming &amp; Software</a>
                  </span>
                  <span>
                    <a href="#">Angular</a>
                  </span>
                  <span className="cusTop">
                    <a href="#">API</a>
                  </span>
                  <span className="cusTop">
                    <a href="#">ASP.Net</a>
                  </span>
                  <span className="cusTop">
                    <a href="#">AWS</a>
                  </span>
                  <br />
                  <span className="cusTop">
                    <a href="#">Cloud Computing</a>
                  </span>
                </div>
                <div className="allportfolliowItem mt-3">
                  <div className="portfolioItem me-2 mt-2">
                    <span className="portCusIcon">
                      <i className="fa-solid fa-link" />
                    </span>
                    <span className="cusMoreService">
                      <a href="#">More Services(19)</a>
                    </span>
                    <span className="CustomLine" />
                  </div>
                  <div className="portfolioItem mt-2">
                    <span className="portCusIcon">
                      <i className="fa-solid fa-image" />
                    </span>
                    <span className="cusMoreService">
                      <a href="#">Portfolio(30)</a>
                    </span>
                    <span className="CustomLine" />
                  </div>
                  <div className="portfolioItem me-2 mt-2 ms-2">
                    <span className="portCusIcon">
                      <i className="fa-regular fa-thumbs-up" />
                    </span>
                    <span className="cusMoreService">
                      <a href="#">Performance</a>
                    </span>
                    <span className="CustomLine" />
                  </div>
                  <div className="portfolioItem mt-2">
                    <span className="portCusIcon">
                      <i className="fa-solid fa-user" />
                    </span>
                    <span className="cusMoreService">
                      <a href="#">About</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="rightSidePart">
            <div
              className="card customCardBorder me-3"
              style={{ width: "34rem" }}
            >
              <div className="card-body">
                <div className="allItem">
                  <div className="checkBox">
                    <input type="checkbox" id="scales" name="scales" />
                  </div>
                  <div className="userIcon ms-2">
                    <i className="fa-regular fa-user" />
                  </div>
                  <div className="paragraphDiv">
                    <span className="scopic">Scopic</span>
                    <span className="member">Member</span>
                    <a href="#">Get a Quote</a>
                    <br />
                    <span className="USACus">
                      Rutland, Massachusetts, United States
                    </span>
                    <br />
                    <span className="custDoller">
                      <i className="fa-solid fa-dollar-sign" />
                    </span>
                    <span className="customPrice">624,064/YR</span>
                    <span className="customLike">
                      <i className="fa-regular fa-thumbs-up" /> 100%
                    </span>
                    <span className="customHeart">
                      <i className="fa-regular fa-heart" />
                    </span>
                  </div>
                  <div className="horizontalLine mt-3" />
                </div>
                <div className="allItem1 mt-2">
                  <div className="imgSection">
                    <img
                      src={talentsellerimg5}
                      className="img-fluid"
                    />
                  </div>
                  <div className="textSectioncus">
                    <span className="custtext1 mt-2">
                      Web Application Development
                    </span>
                    <span className="custDoller1">
                      <i className="fa-solid fa-dollar-sign ms-2" />
                      38/hr
                    </span>
                    <span className="custStart">Starting at $1,000</span>
                    <br />
                  </div>
                  <div className="textSectioncus1">
                    <p>
                      Scopic offers high-quality and affordable web development
                      and design services, providing customized solutions that
                      best fit your business's unique needs. Having over 15
                      years of experience in the soft
                    </p>
                  </div>
                </div>
                <div className="allAppitem">
                  <span>
                    <a href="#">Programming &amp; Development</a>
                  </span>
                  <span>
                    <a href="#">Programming &amp; Software</a>
                  </span>
                  <span>
                    <a href="#">Angular</a>
                  </span>
                  <span className="cusTop">
                    <a href="#">API</a>
                  </span>
                  <span className="cusTop">
                    <a href="#">ASP.Net</a>
                  </span>
                  <span className="cusTop">
                    <a href="#">AWS</a>
                  </span>
                  <br />
                  <span className="cusTop">
                    <a href="#">Cloud Computing</a>
                  </span>
                </div>
                <div className="allportfolliowItem mt-3">
                  <div className="portfolioItem me-2 mt-2">
                    <span className="portCusIcon">
                      <i className="fa-solid fa-link" />
                    </span>
                    <span className="cusMoreService">
                      <a href="#">More Services(19)</a>
                    </span>
                    <span className="CustomLine" />
                  </div>
                  <div className="portfolioItem mt-2">
                    <span className="portCusIcon">
                      <i className="fa-solid fa-image" />
                    </span>
                    <span className="cusMoreService">
                      <a href="#">Portfolio(30)</a>
                    </span>
                    <span className="CustomLine" />
                  </div>
                  <div className="portfolioItem me-2 mt-2 ms-2">
                    <span className="portCusIcon">
                      <i className="fa-regular fa-thumbs-up" />
                    </span>
                    <span className="cusMoreService">
                      <a href="#">Performance</a>
                    </span>
                    <span className="CustomLine" />
                  </div>
                  <div className="portfolioItem mt-2">
                    <span className="portCusIcon">
                      <i className="fa-solid fa-user" />
                    </span>
                    <span className="cusMoreService">
                      <a href="#">About</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


      {/*<section>
  <div className="mainDiv mt-5">
    <div className="container">
      <div className="row g-0">
        <div className="col-md-12">
          <div className="allCard">
            <div
              className="card customCardBorder me-2"
              style={{ width: "33.5rem" }}
            >
              <div className="card-body">
                <div className="allItem">
                  <div className="checkBox">
                    <input type="checkbox" id="scales" name="scales" />
                  </div>
                  <div className="userIcon ms-2">
                    <i className="fa-regular fa-user me-2" />
                  </div>
                  <div className="paragraphDiv">
                    <span className="scopic">Scopic</span>
                    <span className="member">Member</span>
                    <a href="#">Get a Quote</a>
                    <br />
                    <span className="USACus">
                      Rutland, Massachusetts, United States
                    </span>
                    <br />
                    <span className="custDoller">
                      <i className="fa-solid fa-dollar-sign me-2" />
                    </span>
                    <span className="customPrice">624,064/YR</span>
                    <span className="customLike">
                      <i className="fa-regular fa-thumbs-up me-2" /> 100%
                    </span>
                    <span className="customHeart">
                      <i className="fa-regular fa-heart me-2" />
                    </span>
                  </div>
                  <div className="horizontalLine mt-3" />
                </div>
                <div className="allItem1 mt-2">
                  <div className="imgSection">
                    <img
                      src={talentsellerimg1}
                      className="img-fluid"
                    />
                  </div>
                  <div className="textSectioncus">
                    <span className="custtext1 mt-2">Web Development</span>
                    <span className="custDoller1">
                      <i className="fa-solid fa-dollar-sign me-2" />
                      38/hr
                    </span>
                    <span className="custStart">Starting at $1,000</span>
                    <br />
                  </div>
                  <div className="textSectioncus1">
                    <p>
                      Scopic offers high-quality and affordable web development
                      and design services, providing customized solutions that
                      best fit your business's unique needs. Having over 15
                      years of experience in the soft
                    </p>
                  </div>
                </div>
                <div className="allAppitem">
                  <span>
                    <a href="#">Programming &amp; Development</a>
                  </span>
                  <span>
                    <a href="#">Programming &amp; Software</a>
                  </span>
                  <span>
                    <a href="#">Angular</a>
                  </span>
                  <span className="cusTop">
                    <a href="#">API</a>
                  </span>
                  <span className="cusTop">
                    <a href="#">ASP.Net</a>
                  </span>
                  <span className="cusTop">
                    <a href="#">AWS</a>
                  </span>
                  <span className="cusTop">
                    <a href="#">Cloud Computing</a>
                  </span>
                </div>
                <div className="allportfolliowItem mt-3 px-3">
                  <div className="portfolioItem me-2 mt-2">
                    <span className="portCusIcon">
                      <i className="fa-solid fa-link me-2" />
                    </span>
                    <span className="cusMoreService">
                      <a href="#">More Services(19)</a>
                    </span>
                    <span className="CustomLine" />
                  </div>
                  <div className="portfolioItem mt-2">
                    <span className="portCusIcon">
                      <i className="fa-solid fa-image me-2" />
                    </span>
                    <span className="cusMoreService">
                      <a href="#">Portfolio(30)</a>
                    </span>
                    <span className="CustomLine" />
                  </div>
                  <div className="portfolioItem me-2 mt-2">
                    <span className="portCusIcon">
                      <i className="fa-regular fa-thumbs-up me-2" />
                    </span>
                    <span className="cusMoreService">
                      <a href="#">Performance</a>
                    </span>
                    <span className="CustomLine" />
                  </div>
                  <div className="portfolioItem mt-2">
                    <span className="portCusIcon">
                      <i className="fa-solid fa-user me-2" />
                    </span>
                    <span className="cusMoreService">
                      <a href="#">About</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="card customCardBorder me-2"
              style={{ width: "33.5rem" }}
            >
              <div className="card-body">
                <div className="allItem">
                  <div className="checkBox">
                    <input type="checkbox" id="scales" name="scales" />
                  </div>
                  <div className="userIcon ms-2">
                    <i className="fa-regular fa-user me-2" />
                  </div>
                  <div className="paragraphDiv">
                    <span className="scopic">Scopic</span>
                    <span className="member">Member</span>
                    <a href="#">Get a Quote</a>
                    <br />
                    <span className="USACus">
                      Rutland, Massachusetts, United States
                    </span>
                    <br />
                    <span className="custDoller">
                      <i className="fa-solid fa-dollar-sign me-2" />
                    </span>
                    <span className="customPrice">624,064/YR</span>
                    <span className="customLike">
                      <i className="fa-regular fa-thumbs-up me-2" /> 100%
                    </span>
                    <span className="customHeart">
                      <i className="fa-regular fa-heart me-2" />
                    </span>
                  </div>
                  <div className="horizontalLine mt-3" />
                </div>
                <div className="allItem1 mt-2">
                  <div className="imgSection">
                    <img
                      src={talentsellerimg2}
                      className="img-fluid"
                    />
                  </div>
                  <div className="textSectioncus">
                    <span className="custtext1 mt-2">Web Development</span>
                    <span className="custDoller1">
                      <i className="fa-solid fa-dollar-sign me-2" />
                      38/hr
                    </span>
                    <span className="custStart">Starting at $1,000</span>
                    <br />
                  </div>
                  <div className="textSectioncus1">
                    <p>
                      Scopic offers high-quality and affordable web development
                      and design services, providing customized solutions that
                      best fit your business's unique needs. Having over 15
                      years of experience in the soft
                    </p>
                  </div>
                </div>
                <div className="allAppitem ml-2">
                  <span>
                    <a href="#">Programming &amp; Development</a>
                  </span>
                  <span>
                    <a href="#">Programming &amp; Software</a>
                  </span>
                  <span>
                    <a href="#">Angular</a>
                  </span>
                  <span className="cusTop">
                    <a href="#">API</a>
                  </span>
                  <span className="cusTop">
                    <a href="#">ASP.Net</a>
                  </span>
                  <span className="cusTop">
                    <a href="#">AWS</a>
                  </span>
                 
                </div>
                <div className="allportfolliowItem mt-3 px-3">
                  <div className="portfolioItem me-2 mt-2">
                    <span className="portCusIcon">
                      <i className="fa-solid fa-link me-2" />
                    </span>
                    <span className="cusMoreService ">
                      <a href="#">More Services(19)</a>
                    </span>
                    <span className="CustomLine" />
                  </div>
                  <div className="portfolioItem mt-2">
                    <span className="portCusIcon">
                      <i className="fa-solid fa-image me-2" />
                    </span>
                    <span className="cusMoreService">
                      <a href="#">Portfolio(30)</a>
                    </span>
                    <span className="CustomLine" />
                  </div>
                  <div className="portfolioItem me-2 mt-2">
                    <span className="portCusIcon">
                      <i className="fa-regular fa-thumbs-up me-2" />
                    </span>
                    <span className="cusMoreService">
                      <a href="#">Performance</a>
                    </span>
                    <span className="CustomLine" />
                  </div>
                  <div className="portfolioItem mt-2">
                    <span className="portCusIcon">
                      <i className="fa-solid fa-user me-2" />
                    </span>
                    <span className="cusMoreService">
                      <a href="#">About</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </section>*/}

    </div>
  )
}

export default Card
