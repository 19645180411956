import React from 'react'
import BegibBody from '../components/BegibBody'

const Begib = () => {
  return (
    <div>
      <BegibBody/>
    </div>
  )
}

export default Begib
