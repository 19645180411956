import React from 'react'

const Footer = () => {
  return (
    <div>
      <footer>
  <div className="footerPart">
    <div className="container-fluid ">
      <div className="row bg-[#636363] p-5 text-white">
        <div className="col-md-3 ">
          <div className="footerPartHead">
            <h5>Categories</h5>
          </div>
          <div className="footerPartPara text-white">
            <ul>
              <li>
                <a href="javascript:void(0)">App Development</a>
              </li>
              <li>
                <a href="javascript:void(0)">Software Development</a>
              </li>
              <li>
                <a href="javascript:void(0)">Web Development</a>
              </li>
              <li>
                <a href="javascript:void(0)">E-Commerce</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-3">
          <div className="footerPartHead">
            <h5>About</h5>
          </div>
          <div className="footerAbout">
            <ul>
              <li>
                <a href="javascript:void(0)">Careers</a>
              </li>
              <li>
                <a href="javascript:void(0)">Press &amp; News</a>
              </li>
              <li>
                <a href="javascript:void(0)">Partnership</a>
              </li>
              <li>
                <a href="javascript:void(0)">Privacy Policy</a>
              </li>
              <li>
                <a href="javascript:void(0)">Terms of Services</a>
              </li>
              <li>
                <a href="javascript:void(0)">Intellectual Property</a>
              </li>
              <li>
                <a href="javascript:void(0)">Investor Relations</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-3">
          <div className="footerPartHead">
            <h5>Community</h5>
          </div>
          <div className="footerSupport">
            <ul>
              <li>
                <a href="javascript:void(0)">How to find work</a>
              </li>
              <li>
                <a href="javascript:void(0)">Direct Contracts</a>
              </li>
              <li>
                <a href="javascript:void(0)">Find Freelance Jobs worldwide</a>
              </li>
              <li>
                <a href="javascript:void(0)">Win work with post</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-3">
          <div className="footerPartHead">
            <h5>Support and Education</h5>
          </div>
          <div className="footerSupport">
            <ul>
              <li>
                <a href="javascript:void(0)">Blog</a>
              </li>
              <li>
                <a href="javascript:void(0)">Forum</a>
              </li>
              <li>
                <a href="javascript:void(0)">Event</a>
              </li>
              <li>
                <a href="javascript:void(0)">Podcast</a>
              </li>
              <li>
                <a href="javascript:void(0)">Creators</a>
              </li>
              <li>
                <a href="javascript:void(0)">Community Hub</a>
              </li>
              <li>
                <a href="javascript:void(0)">Become a Seller</a>
              </li>
              <li>
                <a href="javascript:void(0)">Community Standards</a>
              </li>
              <li>
                <a href="javascript:void(0)">Customer Success Stories</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

    </div>
  )
}

export default Footer
