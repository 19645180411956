import React from 'react'
import TimingBudgetBody from '../components/TimingBudgetBody'

const TimingBudget = () => {
  return (
    <div>
      <TimingBudgetBody/>
    </div>
  )
}

export default TimingBudget
