import React from "react";
import Breadcrums from "./Breadcrums";
import { Link } from "react-router-dom";
import '@fontsource/lilita-one';
const ProPublishBody = () => {
  return (
    <div>
      {/* header */}
    <div className="px-4 py-1 flex justify-between items-center flex-wrap border-b border-black">
        <div className="flex gap-3 sm:gap-5  items-center">
          <Link to='/'>
          <p className="font-[Lilita One] text-[40px] text-[#F8A529] font-[400] cursor-pointer m-0" 
          style={{fontFamily: 'Lilita One'}}>Silver oak</p></Link>
        
        </div>
        
    </div>
     <Breadcrums/>
      <section>
        <div className="container">
          <div className="row bg-[#F1F1F1]">
            <div className="container">
              <div className=" border border-dark m-10  p-5">
                <h5>
                  <b>Personal & business information</b>
                </h5>
                <p className="mt-3">
                  <b>
                    Verify your personal and business information for compliance
                    purposes. Learn more
                  </b>
                </p>
                <div className="text-end">
                <button className="btn btn-xs sm:btn-sm md:btn-md lg:btn-lg btn-primary ">Verify</button>
                </div>
              </div>
            </div>
            <div className="text-center mb-5">
            <h5><b>You’re almost there!</b></h5>
            <p><b>Let’s publish your Gig get you ready to ready to start selling.</b></p>
          </div>
          </div>

          <div className="my-3 text-end">
            <Link to='/About'>
              <button className="btn btn-xs sm:btn-sm md:btn-md lg:btn-lg btn-primary">Publish Gig</button></Link>
              </div>
            
              
              <div className="my-3 text-end">
              <Link to="/ProGallery">
              <button className="btn btn-xs sm:btn-sm md:btn-md lg:btn-lg btn-secondary ml-3">Back</button></Link>
              
              </div>

        </div>

      
      </section>
    </div>
  );
};

export default ProPublishBody;
