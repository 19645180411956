import React from "react";
import aboutmeimg1 from "../images/aboutmeimg1.png";
import verified from "../images/verified.png";
import mygigsimg1 from "../images/mygigsimg1.png";
import mygigsimg2 from "../images/mygigsimg2.png";
import mygigsimg3 from "../images/mygigsimg3.png";
import mygigsimg4 from "../images/mygigsimg4.png";
import fiverlogo from "../images/fiverLogo.png";
import frame133 from "../images/Frame133.png";
import frame134 from "../images/Frame134.png";
import ratingyellow from "../images/rating-star-yellow.png";
import wordpressimg1 from "../images/wordepressimg1.png";
import silveroak from "../images/SILVEROAK LOGO.png";
import { GoBell } from "react-icons/go";
import { IoMailOutline } from "react-icons/io5";
import { IoMdHeartEmpty } from "react-icons/io";
import { IoSearch } from "react-icons/io5";
import { Link } from "react-router-dom";

const AboutBody = () => {
  return (
    <div>
      {/* header */}
      <div className="px-4 py-1 flex justify-between items-center flex-wrap border-b border-black">
        <div className="flex gap-3 sm:gap-5  items-center">
         <Link to='/' ><p
            className="font-[Lilita One] text-[40px] text-[#F8A529] font-[400] cursor-pointer"
            style={{ fontFamily: "Lilita One" }}
          >
            Silver oak
          </p></Link>
        </div>
        <div className="w-[50%] h-[48px] border rounded-[48px] border-black relative overflow-hidden ">
          <IoSearch className="absolute left-2 top-[10px] h-[28px] w-[28px]" />
          <input
            type="search"
            name=""
            id=""
            className="block w-full h-full rounded-[48px] pl-10 sm:px-16 focus:outline-none text-[13px] text-[#4A4A4A] "
            placeholder="Search for anything"
          />
        </div>
        <div className="flex gap-3 items-center">
          <div className="bg-[#EBEBEB] rounded-[5px] p-2 cursor-pointer ">
            Post a Job
          </div>
          <GoBell className="h-[28px] w-[28px] cursor-pointer" />
          <IoMailOutline className="h-[28px] w-[28px] cursor-pointer " />
          <IoMdHeartEmpty className="h-[28px] w-[28px] cursor-pointer" />
          <div className=" border border-black rounded-[5px] p-2 px-3 rounded-[5px] cursor-pointer ">
            Order
          </div>
          <div className="  h-[45px] w-[45px] bg-[#F8A529] rounded-[50%]  cursor-pointer text-white text-[35px] font-[400] flex items-center justify-center ">
            B
          </div>
        </div>
      </div>
      <>
        <section>
          <div className="container">
            <div className="row mt-5">
              <div className="col-md-8">
                <div className="d-flex position-relative">
                  <img
                    src={aboutmeimg1}
                    className="flex-shrink-0 me-3 h-[100px]  ;"
                    alt="..."
                  />
                  <div>
                    <h5 className="mt-0">
                      Usama T <span>@usamatanveerw4</span>
                      <span>
                        <button
                          className="btn btn-success customColr"
                          type="submit"
                        >
                          <span>
                            <img src={verified} width={30} />{" "}
                          </span>
                          Pro
                        </button>
                      </span>
                    </h5>
                    {/* <h5 class="mt-0">
                              Usama T <span>@usamatanveerw4</span
                              ><span
                                ><button class="btn btn-success" type="submit">
                                  <span><i class="fa-solid fa-certificate"></i></span>Pro
                                </button></span>
                              </h5> */}
                    <p>
                      5.0 (680)
                      <span>
                        <button
                          className="btn btn-success customRatingClr"
                          type="submit"
                        >
                          Top Rated
                          <span />
                          <span>
                            <i className="fa-sharp fa-solid fa-star" />
                          </span>
                          <span>
                            <i className="fa-sharp fa-solid fa-star" />
                          </span>
                          <span>
                            <i className="fa-sharp fa-solid fa-star" />
                          </span>
                          {/* <span><i class="fa-regular fa-star"></i></span>*/}
                        </button>
                      </span>
                    </p>
                    <h5>WordPress Website Developer and Photoshop Expert</h5>
                    <span>
                      <i className="fa-solid fa-location-dot" /> Pakistan
                    </span>
                    <span>
                      <i className="fa-regular fa-comment" /> English, French,
                      Spanish, Urdu
                    </span>
                    <br />
                    <span>
                      <i className="fa-solid fa-circle-check customIconclr" />{" "}
                    </span>
                    Hand-picked by the Fiverr Pro team
                    <h6 className="mt-1 ms-4">
                      Experienced in working with businesses
                    </h6>
                    <h6 className="mt-3 fw-bold">Expert in:</h6>
                    <span>
                      <i className="fa-solid fa-circle-check customIconclr" />
                    </span>
                    Website Development
                    <h6>
                      <span>
                        <i className="fa-solid fa-circle-check customIconclr" />
                      </span>
                      Website Maintenance
                    </h6>
                  </div>
                </div>
                <h5>About me</h5>
                <div className="row">
                  <p>
                    Hey, I'm Usama Tanveer, an undergraduate student who aspires
                    to be a software engineer in the future. I have a firm grip
                    on front-end website development and design.
                  </p>
                  <div id="collapse" style={{ display: "none" }}>
                    <p>
                      I specialize in WordPress, Elementor,and Photoshop with
                      eight years of experience. I love making custom,
                      responsive, and uniq...
                    </p>
                  </div>
                  <a href="#collapse" className="nav-toggle">
                    Read More
                  </a>
                </div>
                <div className="row mt-3  ">
                  <h6 className="fw-bold">Skills</h6>
                  <button
                    type="button"
                    className="btn btn-outline-dark w-25 btn-info text-white"
                  >
                    Wordpress expert
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-dark w-25 btn-info text-white "
                  >
                    Adobe Photoshop Experts
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-dark w-25 btn-info text-white "
                  >
                    Wix expert
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-dark w-25 btn-info text-white "
                  >
                    Wix website designer
                  </button>
                </div>
                <div className="row mt-3">
                  <h6 className="fw-bold">Among my clients</h6>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row border">
                  <div className="centerImg">
                    <img src={aboutmeimg1} alt="..." width={100} />
                  </div>
                  <div className="row text-center mt-4">
                    <h6>Usama T</h6>
                    <h6>Offline • 09:58 AM local time</h6>
                    <div>
                      <button
                        type="button"
                        className="btn btn-warning cusBtnclr"
                        w-50=""
                      >
                        Contact me
                      </button>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn btn-primary mt-3 cusBtnclr1"
                        w-50=""
                      >
                        Book a consultation
                      </button>
                    </div>
                    <div className="mt-3">
                      <p>Average response time: 10 hours</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <h6 className="fw-bold">My Gigs</h6>
            </div>
            <div
              id="carousel-example"
              className="carousel slide hidden-xs"
              data-ride="carousel"
            >
              {/* Wrapper for slides */}
              <div className="carousel-inner">
                <div className="item active">
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="col-item">
                        <div className="photo">
                          <img
                            src={mygigsimg1}
                            className="img-responsive"
                            alt="a"
                          />
                          <p>I will do web design and build complete website</p>
                        </div>
                        <div>
                          <span>
                            <i className="fa-regular fa-star" />
                          </span>
                          <span>4.9(90)</span>
                          <span>From</span>
                          <span>
                            <i className="fa-solid fa-indian-rupee-sign" />
                          </span>
                          <span>6062</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="col-item">
                        <div className="photo">
                          <img
                            src={mygigsimg2}
                            className="img-responsive"
                            alt="a"
                          />
                          <p>
                            I will be your frontend developer with HTML, CSS js,
                            and react
                          </p>
                        </div>
                        <div>
                          <span>
                            <i className="fa-regular fa-star" />
                          </span>
                          <span>5.0 (93)</span>
                          <span>From</span>
                          <span>
                            <i className="fa-solid fa-indian-rupee-sign" />
                          </span>
                          <span>8062</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="col-item">
                        <div className="photo">
                          <img
                            src={mygigsimg3}
                            className="img-responsive"
                            alt="a"
                          />
                          <p>
                            I will be your frontend developer with HTML, CSS js,
                            and react
                          </p>
                        </div>
                        <div>
                          <span>
                            <i className="fa-regular fa-star" />
                          </span>
                          <span>5.0 (93)</span>
                          <span>From</span>
                          <span>
                            <i className="fa-solid fa-indian-rupee-sign" />
                          </span>
                          <span>8062</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="col-item">
                        <div className="photo">
                          <img
                            src={mygigsimg4}
                            className="img-responsive"
                            alt="a"
                          />
                          <p>
                            Our agency will be your full stack developer vue js
                            nuxt js
                          </p>
                        </div>
                        <div>
                          <span>
                            <i className="fa-regular fa-star" />
                          </span>
                          <span>3.9(96)</span>
                          <span>From</span>
                          <span>
                            <i className="fa-solid fa-indian-rupee-sign" />
                          </span>
                          <span>6062</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="workExperincePart">
            <div className="container">
              <div className="row">
                <div className="col-md-5">
                  <div className="workExperincePart1">
                    <h6 className="fw-bold mt-5 mb-4">Work Experince</h6>
                    <div className="workExperincePart2">
                      <div className="workExperincePartImg">
                        <img src={silveroak} width={120} />
                      </div>
                      <div className="workExperincePartImgTxt mt-3">
                        <h6 className="fw-bold">Web Developer</h6>
                        <h6 className="fw-bold">SilverOak</h6>
                        <p>Nov 2015 - Present 8 yrs 5 months</p>
                      </div>
                    </div>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book.
                    </p>
                  </div>
                </div>
                <div className="col-md-7" />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row mt-3">
              <h4>Portfolio</h4>
              <div className="col-md-6">
                <img src={frame133} className="img-fluid" />
              </div>
              <div className="col-md-6">
                <img src={frame134} className="img-fluid" />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <div className="accordion testimonial">
                  <h2 className="pt-5">
                    <a
                      href="javascript:void(0);"
                      className="text-decoration-none"
                    >
                      1-5 out of 580 Reviews
                    </a>
                  </h2>
                  <div className="pane mt-5">
                    <div className="lb">
                      <div className="rating-t">
                        <img src={ratingyellow} alt="Rating" />
                        <span className="custIcon">
                          <i className="fa-solid fa-check ms-3" />
                        </span>
                        <span className="cusTxt">2 months ago</span>
                      </div>
                      {/*<div class="testi-image dfw-FontResize" style="font-size: 15px;"> <img
                                  src="https://i.imgur.com/Vzox3O3.jpg" alt="Testimonial9">
                                  </div>*/}
                      <div className="testi-text mt-4">
                        <span className="quote-l" />
                        Working with Usama was a great experience! Although the
                        project took a little longer than i expected, he did an
                        amazing job and the value for the quality of work and
                        how much he charges is a very great deal. He made all my
                        revisions i requested... See more
                        <span className="quote-r" />
                      </div>
                    </div>
                    <br />
                    <div className="db">
                      <div className="rating-t">
                        <img src={ratingyellow} alt="Rating" />
                        <span className="custIcon">
                          <i className="fa-solid fa-check ms-3" />
                        </span>
                        <span className="cusTxt">2 months ago</span>
                      </div>
                      {/*-<div class="testi-image dfw-FontResize" style="font-size: 15px;"> <img
                                  src="https://i.imgur.com/nHZQTnW.jpg" alt="Testimonial9">
                                  </div>*/}
                      <div className="testi-text mt-4">
                        <span className="quote-l" />
                        We say social, because CodePen is a community. Most
                        creations on CodePen are public and open source. They
                        are living things that other people and the community
                        can interact with, from a simple hearting, to leaving a
                        comment, to forking and changing for their own needs.
                        <span className="quote-r" />
                      </div>
                    </div>
                    <br />
                    <div className="lb">
                      <div className="rating-t">
                        <img src={ratingyellow} alt="Rating" />
                        <span className="custIcon">
                          <i className="fa-solid fa-check ms-3" />
                        </span>
                        <span className="cusTxt">2 months ago</span>
                      </div>
                      {/*<div class="testi-image dfw-FontResize" style="font-size: 15px;"> <img
                                  src="https://i.imgur.com/zfTioar.jpg" alt="Testimonial9">
                                  </div>*/}
                      <div className="testi-text mt-4">
                        <span className="quote-l" />
                        We're trying to make CodePen a happy and healthy place
                        for front-end designers and developers and the people
                        looking for them, while also being a damn fine place to
                        code.
                        <span className="quote-r" />
                      </div>
                    </div>
                    <br />
                    <div className="db">
                      <div className="rating-t">
                        <img src={ratingyellow} alt="Rating" />
                        <span className="custIcon">
                          <i className="fa-solid fa-check ms-3" />
                        </span>
                        <span className="cusTxt">2 months ago</span>
                      </div>
                      {/*<div class="testi-image dfw-FontResize" style="font-size: 15px;"> <img
                                  src="https://i.imgur.com/UM9OsIB.jpg" alt="Testimonial9">
                                  </div>*/}
                      <div className="testi-text mt-4 mb-5">
                        <span className="quote-l" />
                        People use CodePen for all sorts of things: prototyping
                        new ideas, reduced test cases for bugs, sending clients
                        things to look at, evaluating potential hires, to
                        finding inspiration. People also use CodePen as a sort
                        of resume and portfolio, showing off their best design
                        and development work.
                        <span className="quote-r" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row">
                  <div className="rightReviewClient">
                    <div className="rightReviewClientTxt">
                      <h6 className="fw-bold">Ordered:</h6>
                    </div>
                    <div className="rightReviewClientBox">
                      <div className="rightReviewClientBoxImg">
                        <img src={wordpressimg1} width={120} />
                      </div>
                      <div className="rightReviewClientBoxImgDetails d-flex flex-column">
                        <span className="ms-4">WordPress</span>
                        <span className="ms-4">From ₹ 12,476</span>
                      </div>
                    </div>
                  </div>
                  <div className="rightReviewClient1">
                    <div className="rightReviewClientTxt">
                      <h6 className="fw-bold">Ordered:</h6>
                    </div>
                    <div className="rightReviewClientBox">
                      <div className="rightReviewClientBoxImg">
                        <img src={wordpressimg1} width={120} />
                      </div>
                      <div className="rightReviewClientBoxImgDetails d-flex flex-column">
                        <span className="ms-4">WordPress</span>
                        <span className="ms-4">From ₹ 12,476</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <hr />
        <section>
          <div className="doorStep pb-5">
            <div className="container">
              <div className="row doorStepBack">
                <div className="col-md-12">
                  <div className="doorStepof">
                    <h5>Door Step of Freelance services !</h5>
                  </div>
                  <div className="doorStepofbtn">
                    <a href="javascript:void(0)">Join Silver oak</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <hr />
      </>
    </div>
  );
};

export default AboutBody;
