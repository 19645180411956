import React from 'react'
import PersonalInformBody from '../components/PersonalInformBody'

const PersonalInform = () => {
  return (
    <div>
      <PersonalInformBody/>
    </div>
  )
}

export default PersonalInform
