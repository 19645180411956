import React from 'react'
import DescriptionBody from '../components/DescriptionBody'

const Description = () => {
  return (
    <div>
      <DescriptionBody/>
    </div>
  )
}

export default Description
