import React from "react";
import Card from "./Card";
import "@fontsource/lilita-one";
import { GoBell } from "react-icons/go";
import { IoMailOutline } from "react-icons/io5";
import { IoMdHeartEmpty } from "react-icons/io";
import { Link } from "react-router-dom";

const ExploreBody = () => {
  return (
    <>
      {/* header */}
      <div className="px-4 py-1 flex justify-between items-center flex-wrap border-b border-black">
        <div className="flex gap-3 sm:gap-5  items-center">
          <Link to="/">
            <p
              className="font-[Lilita One] text-[40px] text-[#F8A529] font-[400] cursor-pointer"
              style={{ fontFamily: "Lilita One" }}
            >
              Silver oak
            </p>
          </Link>
          <Link to='/SellingOnBuyer'>
          <p className="text-[20px] font-[400] cursor-pointer ">
            Talent Seller
          </p></Link>
          <Link to='/TimingBudget'>
          <p className="text-[20px] font-[400] cursor-pointer ">Buyer</p></Link>
        </div>
        <div className="flex gap-3 items-center">
         
          <div className="bg-[#EBEBEB] rounded-[5px] p-2 cursor-pointer ">
          <Link to='/Begib'>
            Post a Job</Link>
          </div>
          <GoBell className="h-[30px] w-[30px] cursor-pointer" />
          <IoMailOutline className="h-[30px] w-[30px] cursor-pointer " />
          <IoMdHeartEmpty className="h-[30px] w-[30px] cursor-pointer" />
          <div className=" border border-black rounded-[5px] p-2 px-3 rounded-[5px] cursor-pointer ">
            Order
          </div>
          <div className="  h-[45px] w-[45px] bg-[#F8A529] rounded-[50%]  cursor-pointer text-white text-[35px] font-[400] flex items-center justify-center ">
            B
          </div>
        </div>
      </div>
      <div className="pt-3">
        <div className=" container border-black ">
          <div>
            <h6>
              <b>Find and Hire Freelancers</b>
            </h6>
            <p>
              <b>
                We found 1,321,258 Freelancers offering 1,968,148 freelancing
                services online.
              </b>
            </p>
          </div>
          <div className="row mt-5 ">
            <div className="col-md-8">
              <form>
                <div className="flex">
                  <label
                    htmlFor="search-dropdown"
                    className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                  >
                    Your Email
                  </label>
                  <button
                    id="dropdown-button"
                    data-dropdown-toggle="dropdown"
                    className="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
                    type="button"
                  >
                    All categories{" "}
                    <svg
                      aria-hidden="true"
                      className="w-4 h-4 ml-1"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                  <div
                    id="dropdown"
                    className="z-10 hidden bg-white divide-y divide-gray-100 rounded shadow w-44 dark:bg-gray-700"
                    data-popper-reference-hidden=""
                    data-popper-escaped=""
                    data-popper-placement="top"
                    style={{
                      position: "absolute",
                      inset: "auto auto 0px 0px",
                      margin: 0,
                      transform: "translate3d(897px, 5637px, 0px)",
                    }}
                  >
                    <ul
                      className="py-1 text-sm text-gray-700 dark:text-gray-200"
                      aria-labelledby="dropdown-button"
                    >
                      <li>
                        <button
                          type="button"
                          className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          Mockups
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          Templates
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          Design
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          Logos
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="relative w-full">
                    <input
                      type="search"
                      id="search-dropdown"
                      className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg border-l-gray-50 border-l-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                      placeholder="Search Mockups, Logos, Design Templates..."
                      required=""
                    />
                    <button
                      type="submit"
                      className="absolute top-0 right-0 p-2.5 text-sm font-medium text-white bg-blue-700 rounded-r-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                      </svg>
                      <span className="sr-only">Search</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div className="col-md-2">
              <div class="relative inline-block text-left dropdown">
                <button
                  class="inline-flex justify-center w-full rounded-md border
               border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 
               hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <i class="fa-solid fa-location-dot fa-lg me-2"></i>Location
                </button>
                <div class="dropdown-menu origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                  <div
                    class="py-1"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    <a
                      href="#"
                      class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                    >
                      Option 1
                    </a>
                    <a
                      href="#"
                      class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                    >
                      Option 2
                    </a>
                    <a
                      href="#"
                      class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                    >
                      Option 3
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div class="relative inline-block text-center dropdown">
                <button class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <i class="fa-solid fa-bars fa-lg me-2"></i>Filter
                </button>
                <div class="dropdown-menu origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                  <div
                    class="py-1"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    <a
                      href="#"
                      class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                    >
                      Option 1
                    </a>
                    <a
                      href="#"
                      class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                    >
                      Option 2
                    </a>
                    <a
                      href="#"
                      class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                    >
                      Option 3
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Card />
        <Card />
        <Card />
        <Card />

        <section>
          <div className="lordMoreSection">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="lordMoreSection1 mt-4">
                    <a href="#">Load More...</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="container">
          <div className="my-5">
            <h6>
              <b>
                Browse 3 Million+ Professional Services to Get Your Job Done
              </b>
            </h6>
          </div>

          <button
            type="button"
            class="text-white bg-[#24292F] hover:bg-[#00000]/90 focus:ring-4
           focus:outline-display focus:ring-[#3b5998]/50 font-medium rounded-lg
            text-sm px-5 py-2.5 text-center t inline-flex items-center dark:focus:ring-[#3b5998]/55 me-2 mb-2"
          >
            <i class="fa-solid fa-code fa-sm me-2"></i>
            Development & IT
          </button>
          <button
            type="button"
            class="text-white bg-[#1da1f2] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55 me-2 mb-2"
          >
            <i class="fa-solid fa-palette fa-sm me-2"></i>
            Graphics & Design
          </button>
          <button
            type="button"
            class="text-white bg-[#24292F] hover:bg-[#24292F]/90 focus:ring-4 focus:outline-none focus:ring-[#24292F]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 dark:hover:bg-[#050708]/30 me-2 mb-2"
          >
            <i class="fa-solid fa-bullhorn me-2"></i>
            Digital Marketing
          </button>
          <button
            type="button"
            class="text-white bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 me-2 mb-2"
          >
            <i class="fa-solid fa-code fa-sm me-2"></i>
            AI Services
          </button>
        </div>
      </div>
    </>
  );
};

export default ExploreBody;
