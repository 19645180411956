import React from 'react'
import { Link } from 'react-router-dom'

const Breadcrums = () => {
  return (
    <div>
     
       
       <section>
         <div className="container mt-5">
           <div className="row">
             <div className="col-lg-12">
               <nav class="flex" aria-label="Breadcrumb">
                 <ol class="inline-flex flex-wrap  items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                   <li class="inline-flex items-center">
                     {/*<a
                       href="#"
                       class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
                     >
                     
                       Home
                     </a>*/}
                     <Link to="/Professionalone">
                       <span className="bgColorr">1</span>Overview
                     </Link>
                   </li>
                   <li>
                     <div class="flex items-center">
                       <svg
                         class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                         aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         viewBox="0 0 6 10"
                       >
                         <path
                           stroke="currentColor"
                           stroke-linecap="round"
                           stroke-linejoin="round"
                           stroke-width="2"
                           d="m1 9 4-4-4-4"
                         />
                       </svg>
                       <Link to="/ProfessionalTwo">
                         <span className="bgColorr">2</span>Pricing
                       </Link>
                     </div>
                   </li>
                   <li>
                     <div class="flex items-center">
                       <svg
                         class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                         aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         viewBox="0 0 6 10"
                       >
                         <path
                           stroke="currentColor"
                           stroke-linecap="round"
                           stroke-linejoin="round"
                           stroke-width="2"
                           d="m1 9 4-4-4-4"
                         />
                       </svg>
                       <Link to="/ProDescription">
                         <span className="bgColorr">3</span>Description & FAQ
                       </Link>
                     </div>
                   </li>
                   <li>
                     <div class="flex items-center">
                       <svg
                         class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                         aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         viewBox="0 0 6 10"
                       >
                         <path
                           stroke="currentColor"
                           stroke-linecap="round"
                           stroke-linejoin="round"
                           stroke-width="2"
                           d="m1 9 4-4-4-4"
                         />
                       </svg>
                       <Link to="/ProRequire">
                         <span className="bgColorr">4</span>Requirements
                       </Link>
                     </div>
                   </li>
                   <li>
                     <div class="flex items-center">
                       <svg
                         class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                         aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         viewBox="0 0 6 10"
                       >
                         <path
                           stroke="currentColor"
                           stroke-linecap="round"
                           stroke-linejoin="round"
                           stroke-width="2"
                           d="m1 9 4-4-4-4"
                         />
                       </svg>
                       <Link to="/ProGallery">
                         <span className="bgColorr">5</span>Gallery
                       </Link>
                     </div>
                   </li>
                   <li aria-current="page">
                     <div class="flex items-center">
                       <svg
                         class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                         aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         viewBox="0 0 6 10"
                       >
                         <path
                           stroke="currentColor"
                           stroke-linecap="round"
                           stroke-linejoin="round"
                           stroke-width="2"
                           d="m1 9 4-4-4-4"
                         />
                       </svg>
                       <Link to="/ProPublish">
                         <span className="bgColorr">6</span>Publish
                       </Link>
                       {/* <span class="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">
                       Publish
                       </span>*/}
                     </div>
                   </li>
                   <li>
                     <button
                       type="button"
                       class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                     >
                       Save
                     </button>
                   </li>
                   <li>
                     <button
                       type="button"
                       class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                     >
                       Save & Preview
                     </button>
                   </li>
                 </ol>
               </nav>
             </div>
           </div>
         </div>
       </section>
    </div>
  )
}

export default Breadcrums
