import logo from "./logo.svg";
import "./App.css";
// import '@fontsource/lilita-one';
import { Link, Route, Router, BrowserRouter, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import IndexBody from './components/IndexBody';
import Explore from "./pages/Explore";
import About from "./pages/About";
import Description from "./pages/Description";
import TimingBudget from "./pages/TimingBudget";
import ReviewBuyer from "./pages/ReviewBuyer";
import DoneSeller from "./pages/DoneSeller";
import Begib from "./pages/Begib";
import BudgetSeller from "./pages/BudgetSeller";
import ReviewSeller from "./pages/ReviewSeller";
import DoneBuyer from "./pages/DoneBuyer";
import VideoPageSeller from "./pages/VideoPageSeller";
import PersonalInform from "./pages/PersonalInform";
import Professional from "./pages/Professional";
import AccountSecurity from "./pages/AccountSecurity";
import PersonalBusiness from "./pages/PersonalBusiness";
import Professionalone from "./pages/Professionalone";
import ProfessionalTwo from "./pages/ProfessionalTwo";
import ProDescription from "./pages/ProDescription";
import ProRequire from "./pages/ProRequire";
import ProGallery from "./pages/ProGallery";
import ProPublish from "./pages/ProPublish";
import SellingOnBuyer from "./pages/SellingOnBuyer";
import SellingOnBuyerone from "./pages/SellingOnBuyerone";
import SellingOnBuyerTwo from "./pages/SellingOnBuyerTwo";




function App() {
  return (
    <BrowserRouter>
    
     <Routes>
       
       
       
        <Route path='/' element={<IndexBody/>}/>
        <Route path='/explore' element={<Explore/>}/>
        <Route path='/About' element={<About/>}/>
        <Route path='/Description' element={<Description/>}/>
        <Route path='/TimingBudget' element={<TimingBudget/>}/>
        <Route path='/ReviewBuyer' element={<ReviewBuyer/>}/>
        <Route path='/DoneSeller' element={<DoneSeller/>}/>
        <Route path='/Begib' element={<Begib/>}/>
        <Route path='/BudgetSeller' element={<BudgetSeller/>}/>
        <Route path='/ReviewSeller' element={<ReviewSeller/>}/>
        <Route path='/DoneBuyer' element={<DoneBuyer/>}/>
        <Route path='/VideoPageSeller' element={<VideoPageSeller/>}/>
        <Route path='/PersonalInform' element={<PersonalInform/>}/>
        <Route path='/Professional' element={<Professional/>}/>
        <Route path='/AccountSecurity' element={<AccountSecurity/>}/>
        <Route path='/PersonalBusiness' element={<PersonalBusiness/>}/>
        <Route path='/Professionalone' element={<Professionalone/>}/>
        <Route path='/ProfessionalTwo' element={<ProfessionalTwo/>}/>
        <Route path='/ProDescription' element={<ProDescription/>}/>
        <Route path='/ProRequire' element={<ProRequire/>}/>
        <Route path='/ProGallery' element={<ProGallery/>}/>
        <Route path='/ProPublish' element={<ProPublish/>}/>
        <Route path='/SellingOnBuyer' element={<SellingOnBuyer/>}/>
        <Route path='/SellingOnBuyerone' element={<SellingOnBuyerone/>}/>
        <Route path='/SellingOnBuyerTwo' element={<SellingOnBuyerTwo/>}/>
        
        
      
       
       
        
        
        
       
     
       
     </Routes>
     <Footer/>
   </BrowserRouter>





  );
}

export default App;
