import React from "react";
import { Link } from "react-router-dom";

const SellingOnBuyerBody = () => {
  return (
    <div>
      {/* header */}
      <div className="px-4 py-1 flex justify-between items-center flex-wrap border-b border-black">
        <div className="flex gap-3 sm:gap-5  items-center">
          <Link to='/'>
          <p
            className="font-[Lilita One] text-[40px] text-[#F8A529] font-[400] cursor-pointer m-0"
            style={{ fontFamily: "Lilita One" }}
          >
            Silver oak
          </p></Link>
        </div>
      </div>

      <section className="text-gray-600 body-font overflow-hidden">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-wrap -m-12">
            <div className="p-12 md:w-1/2 flex flex-col items-start">
              <h6 className="sm:text-xl text-xl title-font font-medium text-gray-900 mt-4 mb-4">
                Ready to start selling on Silver oak? Here’s the breakdown:
                <hr></hr>
              </h6>

              <h2 className="sm:text-xl text-xl title-font font-medium text-gray-900  mb-4">
                Learn what makes a successful profile
              </h2>
              <p className="leading-relaxed mb-8">
                Add your profile picture, description, and professional
                Information
              </p>

              <h2 className="sm:text-xl text-2xl title-font font-medium text-gray-900 mt-4 mb-4">
                Publish your Gig
              </h2>
              <p className="leading-relaxed mb-8">
                Create a Gig of the service you're offering and start selling
                instantly
              </p>
            </div>
            <div className="p-10 md:w-1/2 flex flex-col items-start">
              <video className="h-full w-full rounded-lg" controls>
                <source
                  src="https://docs.material-tailwind.com/demo.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>

          <div className="mt-5 d-flex justify-content-start">
            <Link to="/SellingOnBuyerone">
              <button type="button" className="btn btn-primary">
                Continue
              </button>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SellingOnBuyerBody;
