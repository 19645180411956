import React from 'react'
import SellingOnBuyeroneBody from '../components/SellingOnBuyeroneBody'

const SellingOnBuyerone = () => {
  return (
    <div>
     <SellingOnBuyeroneBody/> 
    </div>
  )
}

export default SellingOnBuyerone
