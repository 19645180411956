import React from 'react'
import VideoPageSellerBody from '../components/VideoPageSellerBody'

const VideoPageSeller = () => {
  return (
    <div>
      <VideoPageSellerBody/>
    </div>
  )
}

export default VideoPageSeller
