import React from "react";
import Breadcrums from "./Breadcrums";
import { Link } from "react-router-dom";
import '@fontsource/lilita-one';
const ProRequireBody = () => {
  return (
    <div>

      {/* header */}
    <div className="px-4 py-1 flex justify-between items-center flex-wrap border-b border-black">
        <div className="flex gap-3 sm:gap-5  items-center">
          <p className="font-[Lilita One] text-[40px] text-[#F8A529] font-[400] cursor-pointer m-0" style={{fontFamily: 'Lilita One'}}>Silver oak</p>
          
        </div>
        
    </div>
      <Breadcrums/>
      <section>
        <div className="container mt-3">
          <div className="row ">
            <div className="col-md-8 bg-[#F7F7F7]">
              <div className="py-5 m-3">
                <h5>
                  <b>
                    Get all the information you need from buyers to get started
                  </b>
                </h5>
                <p>
                  Add question to help buyers provide you with exactly what you
                  need to start working on order
                </p>
              </div>
              <div className="border-b-2 border-dashed border-black pb-5">
                {" "}
                <h5 className="text-center mt-5">FIVEF QUESTION ?</h5>
              </div>

              <div class="grid grid-cols-1 p-10 justify-center bg-white m-3">
                <div
                  class="w-100 p-2 col-span-3 justify-center justify-self-center 
                     text-black  text-lg "
                >
                  <div>
                    <p>MULITIPLE CHOICE</p>
                    <p>
                      if you’re ordering for a business, what’s your industry?
                    </p>

                    <p className="text-secondary">
                      3D design , e-commerce, accounting, marketing, etc.
                    </p>
                  </div>
                </div>
              </div>

              <div class="grid grid-cols-1 p-10 justify-center bg-white m-3">
                <div
                  class="w-100 p-2 col-span-3 justify-center justify-self-center 
                     text-black  text-lg "
                >
                  <div>
                    <p>MULITIPLE CHOICE</p>
                    <p>
                      2. this order part of a bigger project you're working on?
                    </p>

                    <p className="text-secondary">
                      Building a mobile app ,creating an animation, development
                      a game, etc.
                    </p>
                  </div>
                </div>
              </div>
              <div className="border-b-2 border-dashed border-black pb-5">
                {" "}
                <h5 className="text-center mt-5">Your Questions</h5>
              </div>
              <div className="my-3">
                <p>
                  Here’s where you can request any details needed to complete
                  the order
                </p>
                <p>
                  There’s no need to repeat any of the general questions asked
                  by Fiverr
                </p>
              </div>

              <div class="grid grid-cols-1 p-10 justify-center bg-white m-3">
                <div
                  class="w-100 p-2 col-span-3 justify-center justify-self-center 
                     text-black  text-lg "
                >


 


                  <div className="mt-3">
                    <form class="w-100 ">
                      <label
                        for="countries"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {" "}
                        Get it a from of :
                      </label>
                      <select
                        id="countries"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option selected>Attachment</option>
                        <option value="US">Ameria</option>
                        <option value="CA">Canada</option>
                        <option value="FR">France</option>
                        <option value="DE">Germany</option>
                      </select>
                    </form>
                  </div>{" "}
                  <div className="text-end mt-3">
                    <button
                      type="button"
                      class="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                    >
                      Cancel
                    </button>
                    <button className="btn btn-xs sm:btn-sm md:btn-md lg:btn-lg btn-danger ">Add</button>
                  </div>
                </div>

               
              </div>
              <div className="ml-3">
                    <p>You must Add at least 1 requirement</p>
                </div>

                <div className="my-3">
                <Link to="/ProGallery">
              <button className="btn btn-xs sm:btn-sm md:btn-md lg:btn-lg btn-primary">Save & Continue</button></Link>
              
              </div>
              
              <div className="my-3">
              <Link to="/ProDescription">
              <button className="btn btn-xs sm:btn-sm md:btn-md lg:btn-lg btn-dark ">Back</button></Link>
              
              </div>

            </div>
            

            <div className="col-md-4">
              <div class="text-white py-10 bg-[#4EAD03] text-center">
                <h5>Provide Your Requirements</h5>
                <div className="p-5">
                  <video className="h-full w-full rounded-lg" controls>
                    <source
                      src="https://docs.material-tailwind.com/demo.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div className="p-3">
                  <p>Indicate what you need before you can start working.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProRequireBody;
