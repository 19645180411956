import React from 'react'
import AccountSecurityBody from '../components/AccountSecurityBody'

const AccountSecurity = () => {
  return (
    <div>
      <AccountSecurityBody/>
    </div>
  )
}

export default AccountSecurity
