import React from "react";
import seller from "../images/seller.png";

const BudgetSellerBody = () => {
  return (
    <div>
       {/* header */}
    <div className="px-4 py-1 flex justify-between items-center flex-wrap border-b border-black">
        <div className="flex gap-3 sm:gap-5  items-center">
          <p className="font-[Lilita One] text-[40px] text-[#F8A529] font-[400] cursor-pointer" style={{fontFamily: 'Lilita One'}}>Silver oak</p>
          
        </div>
        
       
    </div>
      <>
        <div className="secondLayout">
          <div className="container">
            <div className="row mt-5">
              <div className="col-md-4">
                <div className="leftSides ">
                  <h1>Now let’s talk budget and timing</h1>
                  <p>
                    You're not committing to anything final here. This just
                    helps us find you the most relevant
                  </p>
                  <img src={seller} width={200} className="img-fluid" />
                </div>
              </div>
              <div className="col-md-8">
                <div className="rightSides">
                  <div className="col-md-8">
                    <form>
                      <div className="form-group">
                        <label htmlFor="formGroupExampleInput">
                          I'm looking to spend...
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="formGroupExampleInput"
                          placeholder={10000}
                        />
                        <p className="text-secondary">
                          Average range for this service: 5,258.75-70,116.64
                          <span>
                            <i className="fa-solid fa-question" />
                          </span>
                        </p>
                      </div>
                      <div className="form-check form-check-inline mb-5">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox1"
                          defaultValue="option1"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineCheckbox1"
                        >
                          My budget is flexible
                        </label>
                      </div>


                      <div id="calendar ">
                        <label htmlFor="date">Let's talk timing</label>
                        <input type="date" id="date" name="date" />
                      </div>
                    </form>
                    <div className="mt-5 d-flex justify-content-end">
                      <button type="button" className="btn btn-primary">
                        Revlew you Brief
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section>
          <div className="doorStep pb-5">
            <div className="container">
              <div className="row doorStepBack">
                <div className="col-md-12">
                  <div className="doorStepof">
                    <h5>Door Step of Freelance services !</h5>
                  </div>
                  <div className="doorStepofbtn">
                    <a href="javascript:void(0)">Join Silver oak</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <hr />
      </>
    </div>
  );
};

export default BudgetSellerBody;
